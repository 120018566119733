<template>
    <v-card v-if="document" class="pa-2 hover-shadow" outlined>
        <v-row align="center">
            <v-col cols="2">
                <v-icon color="primary" size="50">mdi-file-outline</v-icon>
            </v-col>
            <v-col cols="10">
                <v-row>
                    <v-col cols="8">
                        <div class="document-name">
                            {{ document.name }}
                        </div>
                        <div class="document-info">
                            <v-icon>mdi-folder-outline</v-icon>
                            {{ document.folder.name }}
                        </div>
                        <div class="document-info">
                            <v-icon>mdi-upload-outline</v-icon>
                            {{ document.user.name }}
                        </div>
                    </v-col>
                    <v-col cols="4" align="right">
                        <div v-if="withActions">
                            <v-btn v-if="!isClient" fab outlined x-small @click="editDocument">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn v-if="isAdmin" class="ml-1 my-1" fab outlined x-small @click="deleteDocument">
                                <v-icon color="primary">mdi-delete</v-icon>
                            </v-btn>
                            <v-btn class="ml-1 my-1" fab outlined x-small @click="downloadDocument"
                                   :loading="document.loadingDownload">
                                <v-icon color="info">mdi-download</v-icon>
                            </v-btn>
                        </div>
                        <div class="document-date">
                            {{ documentDate }}
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Axios from "@/utils/Axios";
import axios from "axios";
import store from "@/store";

export default {
    name: "DocumentCard",
    props: {
        document: {
            type: Object,
            default: null,
        },
        withActions: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        documentDate(vm = this) {
            return vm.document && vm.$moment(vm.document.created_at).format('DD/MM/YYYY - h:mmA') || 'n/d'
        },
        href(vm = this) {
            return `${process.env.VUE_APP_BASE_URL}${vm.document.filepath}`
        }
    },
    methods: {
        editDocument() {
            const vm = this
            vm.$emit('edit')
        },
        deleteDocument() {
            const vm = this
            vm.$emit('delete')
        },
        async downloadDocument() {
            const vm = this
            let response = await Axios.getFile(`document/${vm.document.id}/download`)
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', vm.document.filename);
            document.body.appendChild(fileLink);

            fileLink.click();
        },
    }
}
</script>

<style scoped>

.document-name {
    font-size: 15px;
    font-weight: bold;
}

.document-info {
    font-size: 13px;
    color: #707070;
}

.document-date {
    font-size: 9px;
    color: #707070;
    position: absolute;
    bottom: 0;
    right: 8px
}

</style>
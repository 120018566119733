<template>
    <v-row :class="[{'mt-4':!noMarginTop}]" :style="style" align="center">
        <v-spacer/>
        <v-progress-circular
            indeterminate
            :color="color"
            :size="size"
        ></v-progress-circular>
        <v-spacer/>
    </v-row>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        noMarginTop: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: '200px',
        },
        color: {
            type: String,
            default: 'primary',
        },
        size: {
            type: Number,
            default: 50,
        }
    },
    computed: {
        style() {
            return `height: ${this.height}`
        }
    }
}
</script>

<style scoped>

</style>
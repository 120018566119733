<template>
    <v-dialog v-model="dialog" fullscreen @click:outside="hide">
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-chart-box-outline</v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Crear' }} Presupuesto
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <!-- HEADERS INPUTS -->
                <v-row class="py-5">
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode || !!clientId"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-date-picker
                            label="Año"
                            placeholder="AAAA"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :min="minYear"
                            :max="maxYear"
                            year-mode
                            :allowed-dates="allowedDates"
                            :value="year"
                            @change="year = $event"
                        />
                    </v-col>
                </v-row>

                <v-row class="background-carousel">
                    <!-- INPUTS -->
                    <v-col cols="12">
                        <div class="title-carousel-item">
                            INCOME STATEMENT / ESTADO DE RESULTADOS
                        </div>

                        <monthly-financial-values-input
                            title="REVENUE / INGRESOS"
                            :template="template"
                            name-label="Ingresos"
                            :values="revenue"
                            @change="revenue = $event"
                            :no-delete-rows="hasFinancial"
                        />

                        <monthly-financial-values-input
                            title="COST OF SALES / COSTO DE VENTAS"
                            :template="template"
                            name-label="Costos"
                            :values="costSale"
                            @change="costSale = $event"
                            :no-delete-rows="hasFinancial"
                        />

                        <monthly-financial-values-input
                            title="OPERATING EXPENSES / GASTOS OPERACIONALES"
                            :template="template"
                            name-label="Gastos"
                            :values="operatingExpenses"
                            @change="operatingExpenses = $event"
                            :no-delete-rows="hasFinancial"
                        />

                        <monthly-financial-values-input
                            title="OTHER EXPENSES/INCOMEN NOT OP/ OTROS GASTOS INGRE NO OPER."
                            :template="template"
                            name-label="Gastos"
                            :values="otherExpenses"
                            @change="otherExpenses = $event"
                            :no-delete-rows="hasFinancial"
                        />

                    </v-col>
                </v-row>
                <!-- CONTINUE & BACK -->
                <v-row class="pa-3">
                    <v-spacer/>
                    <v-btn @click="editMode ? editBudget() : saveBudget()" color="primary" depressed tile
                           :disabled="!validSave" :loading="loading">
                        Guardar Estado Financiero
                    </v-btn>
                    <v-spacer/>
                </v-row>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import MonthlyFinancialValuesInput from "@/components/financial/MonthlyFinancialValuesInput";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";

export default {
    name: "CreateBudgetDialog",
    components: {KimsaDatePicker, MonthlyFinancialValuesInput, KimsaSelect},
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            budget: null,
            hasFinancial: false,
            carousel: 0,
            clients: [],
            client: null,
            year: `${new Date().getFullYear()}-01-01`,
            revenue: [],
            costSale: [],
            operatingExpenses: [],
            otherExpenses: [],
            template: {name: ''},
            budgetYears: [],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        validSave(vm = this) {
            let validRevenue = vm.revenue.filter(r => !r.name).length === 0
            let validCostSale = vm.costSale.filter(r => !r.name).length === 0
            let validOperatingExpenses = vm.operatingExpenses.filter(r => !r.name).length === 0
            let validOtherExpenses = vm.otherExpenses.filter(r => !r.name).length === 0
            return !!vm.client && !!vm.year && validRevenue && validCostSale && validOperatingExpenses && validOtherExpenses
        },
        clientId() {
            return this.$route.params.id || null
        },
        minYear() {
            return `${new Date().getFullYear() - 10}-01-01`
        },
        maxYear() {
            return `${new Date().getFullYear() + 1}-12-31`
        }
    },
    async mounted() {
        const vm = this
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            await vm.loadClients()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.carousel = 0
            vm.budget = null
            vm.hasFinancial = false
            vm.client = null
            vm.year = `${new Date().getFullYear()}-01-01`
            vm.revenue = []
            vm.costSale = []
            vm.operatingExpenses = []
            vm.otherExpenses = []
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
                if (vm.clientId) vm.client = Number(vm.clientId)
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadBudget(id) {
            const vm = this
            await Axios.get(`budget/${id}`).then(res => {
                // console.log('res loadBudget', res)
                vm.budget = res.data.result.budget
                vm.hasFinancial = !!vm.budget.financial_statement
                vm.editMode = true
                vm.client = vm.budget.client_id
                vm.year = vm.budget.year
                vm.revenue = vm.budget.revenue
                vm.costSale = vm.budget.cost_sale
                vm.operatingExpenses = vm.budget.operating_expenses
                vm.otherExpenses = vm.budget.other_expenses
                vm.show()
            }).catch(er => {
                console.log('error loadBudget', er)
            })
        },
        async saveBudget() {
            const vm = this
            vm.loading = true
            // console.log('saveBudget', vm)

            let payload = {
                client_id: vm.client,
                year: vm.year.split('-')[0],
                revenue: vm.revenue,
                cost_sale: vm.costSale,
                operating_expenses: vm.operatingExpenses,
                other_expenses: vm.otherExpenses,
            }

            // console.log('payload', payload)

            await Axios.post('budget/new', payload).then(res => {
                // console.log('res saveBudget',res)
                vm.toast('Presupuesto creado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveBudget', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editBudget() {
            const vm = this
            vm.loading = true
            // console.log('editBudget', vm)

            let payload = {
                client_id: vm.client,
                year: vm.year.split('-')[0],
                revenue: vm.revenue,
                cost_sale: vm.costSale,
                operating_expenses: vm.operatingExpenses,
                other_expenses: vm.otherExpenses,
            }

            if (vm.hasFinancial) {
                let newRevenue = vm.revenue.slice(vm.budget.revenue.length)
                let newCostSale = vm.costSale.slice(vm.budget.cost_sale.length)
                let newOperatingExpenses = vm.operatingExpenses.slice(vm.budget.operating_expenses.length)
                let newOtherExpenses = vm.otherExpenses.slice(vm.budget.other_expenses.length)

                payload.has_financial = true
                payload.new_revenue = newRevenue.map(item => {return {name: item.name}})
                payload.new_cost_sale = newCostSale.map(item => {return {name: item.name}})
                payload.new_operating_expenses = newOperatingExpenses.map(item => {return {name: item.name}})
                payload.new_other_expenses = newOtherExpenses.map(item => {return {name: item.name}})
            }

            console.log('payload', payload)

            await Axios.patch(`budget/${vm.budget.id}/edit`, payload).then(res => {
                // console.log('res editBudget',res)
                vm.toast('Presupuesto actualizado')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editBudget', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        allowedDates(val) {
            const vm = this
            let condition = ''
            vm.budgetYears.forEach((ar, i) => {
                if(i !== 0) condition += ' && '
                condition += `!(val >= '${ar}-01-01' && val < '${Number(ar)+1}-01-01')`
            })
            return eval(condition)
        },
        async getBudgetYears() {
            const vm = this
            if(!vm.client) return
            let budgetYears = await Axios.get(`client/${vm.client}/budget/years`)
            vm.budgetYears = budgetYears.data.result.years
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
        client() {
            this.getBudgetYears()
        },
        budgetYears(val) {
            if(val.includes(this.year.split('-')[0]) && !this.editMode) this.year = null
        }
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

.title-carousel {
    font-size: 11px;
    color: #a0a0a0;
}

.title-carousel-active {
    font-size: 12px;
    color: var(--v-accent-base);
    font-weight: bold;
}

.border-carousel {
    color: #a0a0a0;
    border: 2px solid #a0a0a0;
    border-radius: 10px;
}

.border-carousel-active {
    color: var(--v-accent-base);
    border-color: var(--v-accent-base);
}

.background-carousel {
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #efefef;
    padding: 10px;
    margin: 0;
}

.title-carousel-item {
    color: var(--v-primary-base);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.label-budget {
    font-size: 10px;
    color: #212322;
}

</style>
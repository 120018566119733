<template>
    <div>
        <!-- HEADER -->
        <v-row align="center">
            <v-col cols="6">
                <v-btn v-if="!isClient" class="normal-btn mr-3" color="primary" depressed @click="showUploadDocument">
                    <v-icon left>mdi-file-upload-outline</v-icon>
                    Cargar Documento
                </v-btn>
                <v-btn v-if="!isClient" class="normal-btn" color="primary" depressed @click="showUploadMultiple">
                    <v-icon left>mdi-file-document-multiple-outline</v-icon>
                    Cargar Varios
                </v-btn>
            </v-col>
            <v-col cols="3">
                <kimsa-text-field placeholder="Buscar" append-icon="mdi-magnify" outlined dense solo flat
                    @change="search = $event" :loading="loadingSearch" />
            </v-col>
            <v-col cols="3">
                <kimsa-select placeholder="Seleccionar carpeta" bolder-label :items="folders" outlined solo flat dense
                    clearable item-text="name" item-value="id" force-value :value="folder" @change="folder = $event" />
            </v-col>
        </v-row>

        <!-- BODY -->
        <template v-if="documents.length > 0">
            <v-row class="mb-5">
                <v-col cols="12">
                    <v-data-table :headers="headers" :loading="loading" :search.sync="search" :items="documents"
                        :items-per-page="maxPerPage"
                        :footer-props="{ 'items-per-page-options': [50, 100, 150, 200, -1] }" :custom-sort="customSort"
                        :page.sync="currentPage" :server-items-length="totalItems"
                        @click:row="showEditDocument($event.id)" @update:items-per-page="updateItemsPerPage">
                        <template v-slot:item.name="{ item }">
                            <div class="name-cell-width">
                                <span v-html="item.name" />
                            </div>
                        </template>
                        <template v-slot:item.folderName="{ item }">
                            <div class="cell-width">
                                <span v-html="item.folderName" />
                            </div>
                        </template>
                        <template v-slot:item.date="{ item }">
                            <div class="cell-width">
                                <span v-html="item.date" />
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="actions-cell-width">
                                <v-btn v-if="!isClient" fab outlined x-small @click.stop="showEditDocument(item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="isAdmin" class="ml-1 my-1" fab outlined x-small
                                    @click.stop="showDeleteDocument(item)">
                                    <v-icon color="primary">mdi-delete</v-icon>
                                </v-btn>
                                <v-btn class="ml-1 my-1" fab outlined x-small @click.stop="downloadDocument(item)"
                                    :loading="item.loadingDownload">
                                    <v-icon color="info">mdi-download</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-alert outlined type="info" dense color="accent">
                        {{ search ? 'Sin resultados' : 'Sin documentos cargados aún' }}
                    </v-alert>
                </v-col>
            </v-row>
        </template>

        <!-- COMPONENTS -->
        <upload-document-dialog @uploaded="loadDocuments" ref="upload" />
        <upload-multiple-document-dialog @uploaded="loadDocuments" ref="multiple" />

        <kimsa-confirm-delete title="Estas eliminando un Documento de:"
            content="Esta acción eliminará de forma permanente el documento del sistema." component="DocumentCard"
            :current-data="currentData" persistent @confirm="onConfirmDelete"
            @cancel="currentId = null; currentData = null" :loading="loadingDelete" no-close-on-confirm ref="confirm" />

    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import UploadDocumentDialog from "@/components/document/UploadDocumentDialog";
import KimsaConfirmDelete from "@/components/common/KimsaConfirmDelete";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import UploadMultipleDocumentDialog from '../document/UploadMultipleDocumentDialog.vue';
export default {
    name: "Documents",
    components: { KimsaSelect, KimsaConfirmDelete, UploadDocumentDialog, KimsaTextField, UploadMultipleDocumentDialog },
    data() {
        return {
            loadingSearch: false,
            loadingDelete: false,
            loading: false,
            search: '',
            folders: [],
            folder: null,
            documents: [],
            currentPage: 1,
            maxPerPage: 50,
            currentId: null,
            currentData: null,
            templateHeaders: [
                { text: 'Nombre', value: 'name', class: 'grey white--text' },
                { text: 'Carpeta', value: 'folderName', class: 'grey white--text' },
                { text: 'Fecha', value: 'date', class: 'grey white--text' },
                { text: '', value: 'actions', sortable: false, class: 'grey' },
            ],
            headers: [
                { text: 'Nombre', value: 'name', class: 'grey white--text' },
                { text: 'Carpeta', value: 'folderName', class: 'grey white--text' },
                { text: 'Fecha', value: 'date', class: 'grey white--text' },
                { text: '', value: 'actions', sortable: false, class: 'grey' },
            ]
        }
    },
    computed: {
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadFolders()
        await vm.loadDocuments()
    },
    methods: {
        showUploadDocument() {
            const vm = this
            vm.$refs.upload.show()
        },
        showUploadMultiple() {
            const vm = this
            vm.$refs.multiple.show()
        },
        showEditDocument(id) {
            const vm = this
            console.log('id', id);
            vm.$refs.upload.loadDocument(id)
        },
        showDeleteDocument(data) {
            const vm = this
            vm.currentData = data
            vm.currentId = data.id
            vm.$refs.confirm.show()
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`document/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Documento eliminado')
            }).catch(er => {
                console.log('error onConfirmDelete', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadDocuments()
        },
        async loadDocuments() {
            const vm = this
            vm.loading = true

            let payload = { search: vm.search, per_page: vm.maxPerPage }

            if (vm.folder) payload.folder_id = vm.folder

            await Axios.post(`client/${vm.clientId}/documents?page=${vm.currentPage}`, payload).then(res => {
                // console.log('res loadDocuments', res)
                vm.documents = res.data.result.documents.data.map(doc => {
                    return {
                        ...doc,
                        folderName: doc.folder?.name || '',
                        date: vm.$moment(doc.created_at).format('DD/MM/YYYY - h:mmA'),
                        loadingDownload: false,
                    }
                })
                vm.totalItems = res.data.result.documents.total
            }).catch(er => {
                console.log('error loadDocuments', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        async loadFolders() {
            const vm = this
            await Axios.post(`folders`, { client_id: vm.clientId }).then(res => {
                // console.log('res loadFolders', res)
                vm.folders = res.data.result.folders
            }).catch(er => {
                console.log('error loadFolders', er)
            })
        },
        customSort(items, index, isDesc) {
            const vm = this
            let isDate = index[0] === 'updated_at' || index[0] === 'delivery_date'
            items.sort((a, b) => {
                return !isDesc[0] ?
                    vm.compare(a[index[0]]?.toLowerCase(), b[index[0]]?.toLowerCase(), isDate) :
                    vm.compare(b[index[0]]?.toLowerCase(), a[index[0]]?.toLowerCase(), isDate)
            });
            return items;
        },
        compare(a, b, isDate) {
            const vm = this
            if (isDate) {
                return vm.$moment(a, 'DD-MM-YYYY').valueOf() - vm.$moment(b, 'DD-MM-YYYY').valueOf()
            } else {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
            }
        },
        async downloadDocument(currentDoc) {
            const vm = this
            currentDoc.loadingDownload = true
            let response = await Axios.getFile(`document/${currentDoc.id}/download`)
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', currentDoc.filename);
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
            currentDoc.loadingDownload = false
        },
        async updateItemsPerPage(newItemsPerPage) {
            const vm = this
            vm.maxPerPage = newItemsPerPage
            vm.currentPage = 1
            await this.loadDocuments()
        },
    },
    watch: {
        async currentPage() {
            await this.loadDocuments()
        },
        search(val) {
            if (val.length === 0 || val.length >= 3) this.loadDocuments()
        },
        folder(val) {
            this.currentPage = 1
            this.loadDocuments()

            if (val) {
                this.headers = this.templateHeaders.filter(h => h.value !== 'folderName')
            } else {
                this.headers = this.templateHeaders
            }
        },
    }
}
</script>

<style scoped>
.name-cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 200px;
    max-height: 1.5em;
}

.cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    max-height: 1.5em;
}

.actions-cell-width {
    white-space: nowrap;
    width: 100px;
}
</style>
<template>
    <div>
        <div class="label">
            Usuarios
        </div>
        <v-row v-for="(user,i) in users" :key="i" align="center">
            <v-col cols="5">
                <kimsa-text-field
                    placeholder="Nombre"
                    outlined dense
                    force-value
                    :readonly="users[i].readonly"
                    :rules="nameRules"
                    :value="users[i].name"
                    @change="users[i].name = $event"
                />
            </v-col>
            <v-col cols="5">
                <kimsa-text-field
                    placeholder="Email"
                    outlined dense
                    force-value
                    :readonly="users[i].readonly"
                    :rules="emailRules"
                    inputMode="email"
                    :value="users[i].email"
                    @change="users[i].email = $event"
                />
            </v-col>
            <v-col cols="2" align="center">
                <v-btn fab outlined x-small @click="deleteUser(i, user.id)" :disabled="!isAdmin">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer/>
            <v-btn depressed tile color="grey" dark @click="addUser">
                Agregar Usuario
            </v-btn>
        </v-row>
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
export default {
    name: "CreateUserClient",
    components: {KimsaTextField},
    props: {
        values: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            users: [],
            template: {name: '', email: ''},
            nameRules: [
                v => !!v || 'Campo requerido',
            ],
            emailRules: [
                v => !!v || 'Campo requerido',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email invalido'
                }
            ],
            usersDeleted: [],
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.values && vm.values.length > 0) vm.users = vm.values
            else {
                vm.users = []
                vm.addUser()
            }
        },
        deleteUser(index, userId) {
            const vm = this
            vm.users.splice(index, 1)
            vm.usersDeleted.push(userId)
            if(vm.users.length === 0) vm.addUser()
        },
        addUser() {
            const vm = this
            vm.users = JSON.parse(JSON.stringify(vm.users))
            vm.users.push(JSON.parse(JSON.stringify(vm.template)))
        }
    },
    watch: {
        users: {
            deep: true,
            handler() {this.$emit('change', this.users)}
        },
        usersDeleted(val) {
            this.$emit('deleted', val)
        },
        values() {
            this.setDefaults()
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
    font-weight: bold;
}

</style>
<template>
    <tiptap-vuetify
        :card-props="cardOptions"
        :placeholder="placeholder"
        v-model="model"
        :extensions="extensions"/>
</template>

<script>
import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline,
    Code, Paragraph, BulletList, OrderedList, ListItem,
    Link, Blockquote, HardBreak, HorizontalRule, History
} from 'tiptap-vuetify'

export default {
    name: "KimsaRichEditor",
    components: {TiptapVuetify},
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        forceValue: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Escribir aquí'
        }
    },
    data() {
        return {
            cardOptions: {
                flat: true,
                outlined: true
            },
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            model: '',
        }
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <v-card v-if="meeting" @click="goToMeeting" class="pa-2" outlined>
        <v-row no-gutters align="center">
            <v-col :cols="withActions ? 8 : 12">

                <div v-if="!withActions && !noClientName" class="meeting-client">
                    {{ meetingClient }}
                </div>
                <div class="meeting-title">
                    {{ meeting.name }}
                </div>

                <span class="meeting-info mr-1">
                    <v-icon small>mdi-calendar</v-icon>
                    <b>{{ meetingDate }}</b>
                </span>

                <span class="meeting-info mr-1">
                    <v-icon small>mdi-map-marker-outline</v-icon>
                    <b>{{ meeting.location }}</b>
                </span>

                <span class="meeting-info mr-1">
                    <v-icon small>mdi-pin-outline</v-icon>
                    <b>{{ meetingTasks }}</b>
                </span>

                <span class="meeting-info">
                    <v-icon small>mdi-account-outline</v-icon>
                    <b>{{ meetingParticipants }}</b>
                </span>
            </v-col>
            <v-col v-if="withActions" cols="4" align="right">
                <v-btn v-if="!isClient" class="mr-1 my-1" fab outlined x-small @click.stop="editMeeting">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="mr-1 my-1" fab outlined x-small @click.stop="deleteMeeting">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="downloadMeeting" :loading="meeting.loadingPdf">
                    <v-icon color="info">mdi-download</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "MeetingCard",
    props: {
        meeting: {
            type: Object,
            default: null,
        },
        withActions: {
            type: Boolean,
            default: false,
        },
        noClientName: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        meetingDate(vm = this) {
            if(vm.meeting) {
                if(vm.meeting.date) {
                    return vm.$moment(vm.meeting.date).format('DD/MM/YYYY')
                } else {
                    return vm.$moment(vm.meeting.created_at).format('DD/MM/YYYY')
                }
            }
            return 'n/d'
        },
        meetingClient(vm = this) {
            return vm.meeting && vm.meeting.client && vm.meeting.client.name || 'n/d'
        },
        meetingTasks(vm = this) {
            let quantity = vm.meeting && vm.meeting.tasks && vm.meeting.tasks.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        meetingParticipants(vm = this) {
            let participants = vm.meeting && vm.meeting.participants || null
            let quantity = participants && participants.length || 0
            if(quantity === 0) return 'n/d'
            return quantity > 1 ? `${participants[0].name}+${quantity-1}` : participants[0].name
        },
    },
    methods: {
        goToMeeting() {
            const vm = this
            vm.$emit('click')
        },
        editMeeting() {
            const vm = this
            vm.$emit('edit')
        },
        deleteMeeting() {
            const vm = this
            vm.$emit('delete')
        },
        downloadMeeting() {
            const vm = this
            vm.$emit('download')
        },
    }
}
</script>

<style scoped>

.meeting-client {
    color: var(--v-primary-base);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.meeting-title {
    font-size: 15px;
    font-weight: bold;
}

.meeting-info {
    font-size: 13px;
    color: #707070;
    display: inline-block;
}

</style>
<template>
    <v-row no-gutters align="center">
        <v-col cols="11" class="label">
            <b>{{ label }}</b>
        </v-col>
        <v-col cols="1" align="center">
            <v-btn icon @click="addRow()">
                <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" v-for="(item, i) in model" :key="i" class="mt-3">
            <kimsa-text-field
                :placeholder="placeholder"
                outlined solo flat dense
                force-value
                :value="model[i].comment"
                @change="model[i].comment = $event"
                :append-icon="i !== 0 ? 'mdi-minus-circle-outline' : ''"
                @click:append="deleteRow(i)"
            />
        </v-col>
    </v-row>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
export default {
    name: "TextFieldAdd",
    components: {KimsaTextField},
    props: {
        label: {
            type: String,
            default: null
        },
        template: {
            type: Object,
            default: null
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: null,
        },
        forceValue: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: [],
        }
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    methods: {
        addRow() {
            const vm = this
            vm.model = JSON.parse(JSON.stringify(vm.model))
            vm.model.push(JSON.parse(JSON.stringify(vm.template)))
        },
        deleteRow(index) {
            const vm = this
            vm.model.splice(index, 1)
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
            if(vm.model.length === 0) vm.addRow()
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

</style>
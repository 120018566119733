<template>
    <div>
        <v-card color="backgroundColor" tile flat v-for="(item,i) in model" :key="i" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12">
                    <kimsa-text-field
                        placeholder="Ingresar Tarea"
                        outlined solo flat dense
                        force-value
                        :readonly="readonly"
                        :value="model[i].name"
                        @change="model[i].name = $event"
                        :append-icon="i !== 0 && !readonly ? 'mdi-minus-circle-outline' : ''"
                        @click:append="deleteRow(i)"
                    />
                </v-col>
                <v-col cols="6">
                    <kimsa-select
                        label="Responsable"
                        :items="usersOptions"
                        placeholder="Selecciona Responsable"
                        bolder-label
                        outlined solo
                        flat dense
                        :readonly="readonly"
                        item-text="name"
                        item-value="id"
                        force-value
                        :value="model[i].user"
                        @change="model[i].user = $event"
                    />
                </v-col>
                <v-col cols="6">
                    <kimsa-date-picker
                        label="Fecha Comprometida"
                        placeholder="-- / -- / --"
                        bolder-label
                        outlined solo
                        flat dense
                        :disabled="readonly"
                        force-value
                        :value="model[i].date"
                        @change="model[i].date = $event"
                    />
                </v-col>
                <v-col cols="6">
                    <kimsa-select
                        label="Status"
                        bolder-label
                        :items="statusOptions"
                        outlined solo
                        flat dense
                        :readonly="readonly"
                        item-text="name"
                        item-value="id"
                        with-avatar-color
                        :value="model[i].status"
                        @change="model[i].status = $event"
                    />
                </v-col>
                <v-col cols="6">
                    <kimsa-select
                        label="Prioridad"
                        :items="priorityOptions"
                        bolder-label
                        outlined solo
                        flat dense
                        :readonly="readonly"
                        item-text="name"
                        item-value="id"
                        force-value
                        :value="model[i].priority"
                        @change="model[i].priority = $event"
                    />
                </v-col>
            </v-row>
        </v-card>
        <v-btn v-if="!readonly" class="normal-btn ml-2" color="grey" dark depressed @click="addRow">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Añadir Tarea
        </v-btn>
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import Axios from "@/utils/Axios";
export default {
    name: "AddTaskField",
    components: {KimsaSelect, KimsaDatePicker, KimsaTextField},
    props: {
        label: {
            type: String,
            default: 'Tarea'
        },
        value: {
            type: Array,
            default: null,
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        usersOptions: {
            type: Array,
            default: () => [],
        },
        statusOptions: {
            type: Array,
            default: () => [],
        },
        priorityOptions: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: [],
            template: {
                name: '',
                user: '',
                date: '',
                status: 1,
                priority: 1,
            },
        }
    },
    async mounted() {
        const vm = this;
        vm.setDefaults()
    },
    methods: {
        addRow() {
            const vm = this
            vm.model = JSON.parse(JSON.stringify(vm.model))
            vm.model.push(JSON.parse(JSON.stringify(vm.template)))
        },
        deleteRow(index) {
            const vm = this
            if(vm.readonly) return
            vm.model.splice(index, 1)
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
            if(vm.model.length === 0) vm.addRow()
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        },
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

</style>
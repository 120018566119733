var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(!_vm.isClient)?_c('v-btn',{staticClass:"normal-btn",attrs:{"color":"primary","depressed":""},on:{"click":_vm.showCreateMeeting}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Crear Acta De Reunión ")],1):_c('v-btn',{staticClass:"normal-btn",attrs:{"color":"black","dark":"","depressed":""},on:{"click":_vm.goToVideo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-video-outline")]),_vm._v(" Videollamadas ")],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('kimsa-text-field',{attrs:{"placeholder":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","solo":"","flat":"","loading":_vm.loadingSearch},on:{"change":function($event){_vm.search = $event}}})],1)],1),(_vm.meetings.length > 0)?[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{staticClass:"12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"items":_vm.meetings,"items-per-page":_vm.maxPerPage,"footer-props":{ 'items-per-page-options': [50, 100, 150, 200, -1] },"custom-sort":_vm.customSort,"page":_vm.currentPage,"server-items-length":_vm.totalItems},on:{"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.currentPage=$event},"click:row":function($event){return _vm.showEditMeeting($event.id)},"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name-cell-width"},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-width"},[_c('span',{domProps:{"innerHTML":_vm._s(item.date)}})])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-width"},[_c('span',{domProps:{"innerHTML":_vm._s(item.location)}})])]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-width"},[_c('span',{domProps:{"innerHTML":_vm._s(item.participants)}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-cell-width"},[(!_vm.isClient)?_c('v-btn',{attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showEditMeeting(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"ml-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteMeeting(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-1 my-1",attrs:{"fab":"","outlined":"","x-small":"","loading":item.loadingPdf},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPdf(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-download")])],1)],1)]}}],null,false,1742241902)})],1)],1)]:[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"outlined":"","type":"info","dense":"","color":"accent"}},[_vm._v(" "+_vm._s(_vm.search ? 'Sin resultados' : 'Sin Actas de Reunión aún')+" ")])],1)],1)],_c('create-meeting-dialog',{ref:"creator",on:{"success":_vm.loadMeetings}}),_c('kimsa-confirm-delete',{ref:"confirm",attrs:{"title":"Estas eliminando un Acta de Reunión de:","content":"Esta acción eliminará de forma permanente el Acta del sistema.","component":"MeetingCard","current-data":_vm.currentData,"persistent":"","loading":_vm.loadingDelete,"no-close-on-confirm":""},on:{"confirm":_vm.onConfirmDelete,"cancel":function($event){_vm.currentId = null; _vm.currentData = null}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
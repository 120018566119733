<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">
                    {{ editMode ? 'mdi-account-outline' : 'mdi-account-plus-outline' }}
                </v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Agregar' }} Cliente
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Nombre del Cliente"
                            placeholder="Ingresar Nombre"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-file
                            label="Logo Empresa"
                            bolder-label
                            outlined
                            dense
                            @change="file = $event"
                            :placeholder="filename"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-divider/>
                    </v-col>

                    <v-col cols="6">
                        <kimsa-text-field
                            label="Nombre Empresa"
                            placeholder="Ingresar nombre"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="company_name"
                            @change="company_name = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Razón social"
                            placeholder="Ingresar razón social"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="business_name"
                            @change="business_name = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="RUT Empresa"
                            placeholder="Ingresar RUT"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="company_rut | rut"
                            @change="value => company_rut = value ? value.replace(/\.\-/g, '') : value"
                            :rules="rutRules"
                            type="text"
                            maxlength="12"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Giro Empresa"
                            placeholder="Ingresar giro"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="business_turn"
                            @change="business_turn = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Dirección Empresa"
                            placeholder="Ingresar dirección"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="address"
                            @change="address = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Régimen de tributación"
                            placeholder="Ingresar régimen"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="tax_regime"
                            @change="tax_regime = $event"
                        />
                    </v-col>

                    <v-col cols="12" class="label pb-0">
                        <b>Representante Empresa</b>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <kimsa-text-field
                            placeholder="Nombre"
                            bolder-label
                            outlined dense
                            force-value
                            :value="representative.name"
                            @change="representative.name = $event"
                        />
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <kimsa-text-field
                            placeholder="Email"
                            outlined dense
                            force-value
                            inputMode="email"
                            :value="representative.email"
                            @change="representative.email = $event"
                        />
                    </v-col>

                    <v-col cols="12" class="label pb-0">
                        <b>Segundo Representante Empresa</b>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <kimsa-text-field
                            placeholder="Nombre"
                            bolder-label
                            outlined dense
                            force-value
                            :value="second_representative.name"
                            @change="second_representative.name = $event"
                        />
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <kimsa-text-field
                            placeholder="Email"
                            outlined dense
                            force-value
                            inputMode="email"
                            :value="second_representative.email"
                            @change="second_representative.email = $event"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-divider/>
                    </v-col>

                    <v-col cols="12">
                        <create-user-client
                            :values="users"
                            @change="users = $event"
                            @deleted="usersDeleted = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editClient() : saveClient()" tile color="primary" depressed
                               :disabled="!valid || loadingDelete" :loading="loading">
                            {{ editMode ? 'GUARDAR' : 'AGREGAR' }}
                        </v-btn>
                        <v-btn @click="showDeleteClient" tile depressed color="grey" dark class="ml-2"
                               :loading="loadingDelete" v-if="editMode && isAdmin">
                            <v-icon>mdi-delete-outline</v-icon>
                            ELIMINAR
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <kimsa-confirm
            v-if="editMode"
            title="Eliminar"
            content="¿Esta seguro que desea eliminar este Cliente?"
            @confirm="onConfirmDelete"
            ref="confirm"
            no-dialog-active
        />
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import KimsaFile from "@/components/common/inputs/KimsaFile";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import CreateUserClient from "@/components/client/CreateUserClient";
import {rutValidator} from "@/utils/Common";

export default {
    name: "CreateClientDialog",
    components: {
        CreateUserClient,
        KimsaConfirm,
        KimsaFile,
        KimsaTextField
    },
    data() {
        return {
            dialog: false,
            loading: false,
            loadingDelete: false,
            editMode: false,
            client: null,
            name: '',
            filename: 'Seleccionar imagen',
            file: null,
            company_name: '',
            business_name: '',
            company_rut: '',
            business_turn: '',
            address: '',
            tax_regime: '',
            representative: {name: '', email: ''},
            second_representative: {name: '', email: ''},
            users: [],
            usersDeleted: [],
            rutRules: [
                rutValidator
            ],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            let userRequireData = !vm.users.find(user => !user.name || !vm.validEmail(user.email))
            return !!vm.name && !!vm.company_name && !!vm.business_name &&
                (!!vm.company_rut && rutValidator(vm.company_rut)) && !!vm.business_turn && !!vm.address &&
                !!vm.representative.name && vm.validEmail(vm.representative.email) &&
                userRequireData
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.name = ''
            vm.image = null
            vm.filename = 'Seleccionar imagen'
            vm.company_name = ''
            vm.business_name = ''
            vm.company_rut = ''
            vm.business_turn = ''
            vm.address = ''
            vm.tax_regime = ''
            vm.representative = {name: '', email: ''}
            vm.second_representative = {name: '', email: ''}
            vm.users = []
            vm.backupUsers = []
        },
        async loadClient(id) {
            const vm = this
            await Axios.get(`client/${id}`).then(res => {
                // console.log('res loadClient', res)
                vm.client = res.data.result.client
                vm.editMode = true
                vm.name = vm.client.name
                vm.filename = vm.client.filename || 'Seleccionar imagen'
                vm.company_name = vm.client.company_name
                vm.business_name = vm.client.business_name
                vm.company_rut = vm.client.company_rut
                vm.business_turn = vm.client.business_turn
                vm.address = vm.client.address
                vm.tax_regime = vm.client.tax_regime
                vm.representative = JSON.parse(JSON.stringify(vm.client.representative))
                let secondRepresentative = JSON.parse(JSON.stringify(vm.client.second_representative))
                vm.second_representative = !secondRepresentative ? {name: '', email: ''} : secondRepresentative
                vm.users = vm.client.users?.map(user => {
                    return {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        readonly: !user.need_change_password,
                    }
                }) || []
                vm.backupUsers = JSON.parse(JSON.stringify(vm.users))
                vm.show()
            }).catch(er => {
                console.log('error loadClient', er)
            })
        },
        async saveClient() {
            const vm = this
            vm.loading = true

            let payload = new FormData()
            payload.append('name', vm.name)
            payload.append('company_name', vm.company_name)
            payload.append('business_name', vm.business_name)
            payload.append('company_rut', vm.company_rut)
            payload.append('business_turn', vm.business_turn)
            payload.append('address', vm.address)
            payload.append('representative', JSON.stringify(vm.representative))
            payload.append('second_representative', JSON.stringify(vm.second_representative))
            payload.append('users', JSON.stringify(vm.users))

            if(vm.file) payload.append('file', vm.file)
            if(vm.tax_regime) payload.append('tax_regime', vm.tax_regime)

            let headers = {headers: {'Content-Type': 'multipart/form-data'}}
            await Axios.post('client/new', payload, headers).then(res => {
                // console.log('res saveClient', res)

                if (res.data.result.ok) {
                    vm.toast('Cliente agregado')
                    vm.$emit('success')
                } else {
                    vm.toast(res.data.message, 'error')
                }

            }).catch(error => {
                console.log('error saveClient', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editClient() {
            const vm = this
            vm.loading = true

            // console.log('editClient', vm)

            let payload = new FormData()
            payload.append('_method', 'PATCH');
            payload.append('name', vm.name)
            payload.append('company_name', vm.company_name)
            payload.append('business_name', vm.business_name)
            payload.append('company_rut', vm.company_rut)
            payload.append('business_turn', vm.business_turn)
            payload.append('address', vm.address)
            payload.append('representative', JSON.stringify(vm.representative))
            payload.append('second_representative', JSON.stringify(vm.second_representative))

            payload.append('users', JSON.stringify(vm.users))
            payload.append('users_deleted', JSON.stringify(vm.usersDeleted))

            if(vm.file) payload.append('file', vm.file)
            if(vm.tax_regime) payload.append('tax_regime', vm.tax_regime)

            let headers = {headers: {'Content-Type': 'multipart/form-data'}}
            await Axios.post(`client/${vm.client.id}/edit`, payload, headers).then(res => {
                // console.log('res editClient', res)
                if(res.data.result.ok) {
                    vm.toast('Cliente editado')
                    vm.$emit('success')
                } else {
                    vm.toast(res.data.message, 'error')
                }
            }).catch(error => {
                console.log('error editClient', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        showDeleteClient() {
            const vm = this
            vm.$refs.confirm.show()
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`client/${vm.client.id}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Cliente eliminado')
                vm.$emit('deleted')
            }).catch(er => {
                console.log('er', er)
                vm.toast('error onConfirmDelete', er)
            }).finally(() => {
                vm.loadingDelete = false
                vm.hide()
            })
        },
        validEmail(email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(email) || false
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>
<template>
    <div>
        <!-- HEADER -->
        <v-row>
            <v-col cols="6">
                <v-row class="pa-3" align="center">
                    <v-btn v-if="!isClient" class="normal-btn" color="primary" depressed @click="showCreateTask">
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        Crear Tarea
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div v-for="status in statusOptions" :key="status.value" class="status-text">
                        <v-avatar size="13" :color="status.color"></v-avatar>
                        {{ status.name }}
                    </div>
                    <v-spacer v-if="isClient"></v-spacer>
                </v-row>
            </v-col>
            <v-col cols="6">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                    :loading="loadingSearch"
                />
            </v-col>
        </v-row>

        <!-- BODY -->
        <template v-if="tasks.length > 0">
            <v-row class="mb-5">
                <v-col cols="6" v-for="(task,i) in tasks" :key="task.id" :class="[{'border-separation': i%2===0}]">
                    <task-card with-actions @click="showDetailTask(task.id)" @edit="showEditTask(task.id)"
                               @delete="showDeleteTask(task)" :task="task"/>
                </v-col>
            </v-row>
<!--            <div v-if="maxLength > 1" class="float-right">-->
                <v-pagination
                    v-model="currentPage"
                    :length="maxLength"
                    circle
                ></v-pagination>
<!--            </div>-->
        </template>
        <template v-else>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-alert outlined type="info" dense color="accent">
                        {{ search ? 'Sin resultados' : 'Sin Tareas aún' }}
                    </v-alert>
                </v-col>
            </v-row>
        </template>

        <!-- COMPONENTS -->
        <create-task-dialog @success="loadTasks" ref="creator" />
        <detail-task-dialog ref="detail"/>

        <kimsa-confirm-delete
            title="Estas eliminando una Tarea de:"
            content="Esta acción eliminará de forma permanente la Tarea del sistema."
            component="TaskCard"
            :current-data="currentData"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null; currentData = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import TaskCard from "@/components/task/TaskCard";
import CreateTaskDialog from "@/components/task/CreateTaskDialog";
import DetailTaskDialog from "@/components/task/DetailTaskDialog";
import KimsaConfirmDelete from "@/components/common/KimsaConfirmDelete";

export default {
    name: "Tasks",
    components: {KimsaConfirmDelete, DetailTaskDialog, CreateTaskDialog, TaskCard, KimsaTextField},
    data() {
        return {
            loadingSearch: false,
            loadingDelete: false,
            loading: false,
            search: '',
            tasks: [],
            currentPage: 1,
            maxLength: 1,
            maxPerPage: 1,
            currentId: null,
            currentData: null,
            statusOptions: [],
        }
    },
    computed: {
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadStatus()
        await vm.loadTasks()
    },
    methods: {
        showCreateTask() {
            const vm = this
            vm.$refs.creator.show()
        },
        showEditTask(id) {
            const vm = this
            vm.$refs.creator.loadTask(id)
        },
        showDeleteTask(data) {
            const vm = this
            vm.currentData = data
            vm.currentId = data.id
            vm.$refs.confirm.show()
        },
        showDetailTask(id) {
            const vm = this
            vm.$refs.detail.show(id)
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`manager-task/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete', res)
                vm.toast('Tarea eliminada')
            }).catch(er => {
                console.log('error onConfirmDelete', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadTasks()
        },
        async loadStatus() {
            const vm = this
            await Axios.get('status').then(res => {
                // console.log('res loadStatus', res.data.result)
                vm.statusOptions = res.data.result.status
            }).catch(er => {
                console.log('error loadStatus', er)
            })
        },
        async loadTasks() {
            const vm = this
            vm.loading = true

            await Axios.post(`client/${vm.clientId}/manager-tasks?page=${vm.currentPage}`, {search: vm.search}).then(res => {
                // console.log('res loadTasks', res)
                vm.tasks = res.data.result.manager_tasks.data
                vm.maxLength = res.data.result.manager_tasks.last_page
            }).catch(er => {
                console.log('error loadTasks', er)
            }).finally(() => {
                vm.loading = false
            })
        },
    },
    watch: {
        async currentPage() {
            await this.loadTasks()
        },
        search(val) {
            if (val.length === 0 || val.length >= 3) this.loadTasks()
        }
    }
}
</script>

<style scoped>

.border-separation {
    border-right: 2px dashed rgba(51, 51, 51, .4);
}

.status-text {
    display: inline-block;
    font-size: 11px;
    margin-right: 4px;
}

</style>
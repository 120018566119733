<template>
    <v-card v-if="task" @click="goToTask" class="pa-2" outlined>
        <v-row align="center">
            <v-col align="center" cols="1">
                <v-avatar size="20" :color="statusColor"></v-avatar>
            </v-col>
            <v-col :cols="withActions ? 7 : 11">
                <div v-if="!withActions" class="task-client">
                    {{ taskClient }}
                </div>
                <div class="task-title text-truncate">
                    {{ taskName }}
                </div>
                <v-row no-gutters align="center">
                    <div class="task-info" v-if="!withActions">
                        <span class="mr-2 d-inline-block">
                            <v-icon small>mdi-account-outline</v-icon>
                            <b>{{ taskUser }}</b>
                        </span>

                        <span class="mr-2 d-inline-block">
                            <v-icon small>mdi-clock-outline</v-icon>
                            <b>Ult. Act.: {{ taskLastUpdated }}</b>
                        </span>

                        <span class="d-inline-block">
                            <v-icon small>mdi-calendar</v-icon>
                            <b>Entrega: {{ taskDate }}</b>
                        </span>
                    </div>
                    <template v-else>
                        <v-col cols="6" class="task-info">
                            <v-icon small>mdi-account-outline</v-icon>
                            <b>{{ taskUser }}</b>
                        </v-col>
                        <v-col cols="6" class="task-info">
                            <v-icon small>mdi-clock-outline</v-icon>
                            <b>Ult. Act.: {{ taskLastUpdated }}</b>
                        </v-col>
                        <v-col cols="6" class="task-info">
                            <v-icon small>mdi-calendar</v-icon>
                            <b>Entrega: {{ taskDate }}</b>
                        </v-col>
                        <v-col cols="6" class="task-info">
                            <v-icon small>mdi-account-outline</v-icon>
                            <b>Comentarios: {{ taskComments }}</b>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
            <v-col v-if="withActions && !isClient" cols="4" align="right">
                <v-btn fab outlined x-small @click.stop="editTask">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="ml-1" fab outlined x-small @click.stop="deleteTask">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "TaskCard",
    props: {
        task: {
            type: Object,
            default: null,
        },
        withActions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        taskName(vm = this) {
            return vm.task && vm.task.name.replace(/<[^>]*>?/gm, '') || 'n/d'
        },
        statusColor(vm = this) {
            return vm.task && vm.task.status && vm.task.status.color || 'black'
        },
        taskClient(vm = this) {
            return vm.task && vm.task.client && vm.task.client.name || 'n/d'
        },
        taskUser(vm = this) {
            return vm.task && vm.task.user && vm.task.user.name || 'n/d'
        },
        taskLastUpdated(vm = this) {
            return vm.task && vm.$moment(vm.task.updated_at).format('DD/MM/YYYY') || 'n/d'
        },
        taskDate(vm = this) {
            return vm.task && vm.$moment(vm.task.created_at).format('DD/MM/YYYY') || 'n/d'
        },
        taskComments(vm = this) {
            let quantity = vm.task && vm.task.comments && vm.task.comments.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
    },
    methods: {
        goToTask() {
            const vm = this
            vm.$emit('click')
        },
        editTask() {
            const vm = this
            vm.$emit('edit')
        },
        deleteTask() {
            const vm = this
            vm.$emit('delete')
        }
    }
}
</script>

<style scoped>

.task-client {
    color: var(--v-primary-base);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.task-title {
    font-size: 15px;
    font-weight: bold;
}

.task-info {
    font-size: 13px;
    color: #707070;
}

</style>
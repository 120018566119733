<template>
    <div>
        <!-- HEADER -->
        <v-row>
            <v-col cols="6">
                <v-btn v-if="!isClient" class="normal-btn" color="primary" depressed @click="showCreateCausal">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Causa
                </v-btn>
            </v-col>
            <v-col cols="6">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                    :loading="loadingSearch"
                />
            </v-col>
        </v-row>

        <!-- BODY -->
        <template v-if="causals.length > 0">
            <v-row class="mb-5">
                <v-col cols="6" v-for="(causal,i) in causals" :key="causal.id" :class="[{'border-separation': i%2===0}]">
                    <causal-card @click="showCausal(causal.id)" @edit="showEditCausal(causal.id)" @delete="showDeleteCausal(causal)" :causal="causal" with-actions/>
                </v-col>
            </v-row>
            <div v-if="maxLength > 1" class="float-right">
                <v-pagination
                    v-model="currentPage"
                    :length="maxLength"
                    circle
                ></v-pagination>
            </div>
        </template>
        <template v-else>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-alert outlined type="info" dense color="accent">
                        {{ search ? 'Sin resultados' : 'Sin causales aún' }}
                    </v-alert>
                </v-col>
            </v-row>
        </template>

        <!-- COMPONENTS -->
        <create-causal-dialog @success="loadCausals" ref="creator" />

        <kimsa-confirm-delete
            title="Estas eliminando una Causal de:"
            content="Esta acción eliminará de forma permanente la causal del sistema."
            component="CausalCard"
            :current-data="currentData"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null; currentData = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import CausalCard from "@/components/causal/CausalCard";
import Axios from "@/utils/Axios";
import CreateCausalDialog from "@/components/causal/CreateCausalDialog";
import KimsaConfirmDelete from "@/components/common/KimsaConfirmDelete";
export default {
    name: "Causals",
    components: {KimsaConfirmDelete, CreateCausalDialog, CausalCard, KimsaTextField},
    data() {
        return {
            loadingSearch: false,
            loadingDelete: false,
            loading: false,
            search: '',
            causals: [],
            currentPage: 1,
            maxLength: 1,
            maxPerPage: 1,
            currentId: null,
            currentData: null,
        }
    },
    computed: {
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadCausals()
    },
    methods: {
        showCreateCausal() {
            const vm = this
            vm.$refs.creator.show()
        },
        showEditCausal(id) {
            const vm = this
            vm.$refs.creator.loadCausal(id)
        },
        showCausal(id) {
            const vm = this
            vm.$refs.creator.loadCausal(id, true)
        },
        showDeleteCausal(data) {
            const vm = this
            vm.currentData = data
            vm.currentId = data.id
            vm.$refs.confirm.show()
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`causal/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Causa eliminada')
            }).catch(er => {
                console.log('error onConfirmDelete',er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadCausals()
        },
        async loadCausals() {
            const vm = this
            vm.loading = true

            await Axios.post(`client/${vm.clientId}/causals?page=${vm.currentPage}`, {search: vm.search}).then(res => {
                // console.log('res loadCausals',res)
                vm.causals = res.data.result.causals.data
                vm.maxLength = res.data.result.causals.last_page
            }).catch(er => {
                console.log('error loadCausals', er)
            }).finally(() => {
                vm.loading = false
            })
        },
    },
    watch: {
        async currentPage() {
            await this.loadCausals()
        },
        search(val) {
            if(val.length === 0 || val.length >= 3) this.loadCausals()
        }
    }
}
</script>

<style scoped>

.border-separation {
    border-right: 2px dashed rgba(51,51,51,.4);
}

</style>
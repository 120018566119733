<template>
    <div>
        <!-- HEADER -->
        <v-row>
            <v-col cols="6">
                <v-btn v-if="!isClient" class="normal-btn" color="primary" depressed @click="showCreateMeeting">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Acta De Reunión
                </v-btn>
                <v-btn v-else color="black" dark depressed class="normal-btn" @click="goToVideo">
                    <v-icon left>mdi-video-outline</v-icon>
                    Videollamadas
                </v-btn>
            </v-col>
            <v-col cols="6">
                <kimsa-text-field placeholder="Buscar" append-icon="mdi-magnify" outlined dense solo flat
                    @change="search = $event" :loading="loadingSearch" />
            </v-col>
        </v-row>

        <!-- BODY -->
        <template v-if="meetings.length > 0">
            <v-row class="mb-5">
                <v-col class="12">
                    <v-data-table :headers="headers" :loading="loading" :search.sync="search" :items="meetings"
                        :items-per-page="maxPerPage"
                        :footer-props="{ 'items-per-page-options': [50, 100, 150, 200, -1] }" :custom-sort="customSort"
                        :page.sync="currentPage" :server-items-length="totalItems"
                        @click:row="showEditMeeting($event.id)" @update:items-per-page="updateItemsPerPage">
                        <template v-slot:item.name="{ item }">
                            <div class="name-cell-width">
                                <span v-html="item.name" />
                            </div>
                        </template>
                        <template v-slot:item.date="{ item }">
                            <div class="cell-width">
                                <span v-html="item.date" />
                            </div>
                        </template>
                        <template v-slot:item.location="{ item }">
                            <div class="cell-width">
                                <span v-html="item.location" />
                            </div>
                        </template>
                        <template v-slot:item.participants="{ item }">
                            <div class="cell-width">
                                <span v-html="item.participants" />
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="actions-cell-width">
                                <v-btn v-if="!isClient" fab outlined x-small @click.stop="showEditMeeting(item.id)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="isAdmin" class="ml-1 my-1" fab outlined x-small
                                    @click.stop="showDeleteMeeting(item)">
                                    <v-icon color="primary">mdi-delete</v-icon>
                                </v-btn>
                                <v-btn class="ml-1 my-1" fab outlined x-small @click.stop="downloadPdf(item)"
                                    :loading="item.loadingPdf">
                                    <v-icon color="info">mdi-download</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-alert outlined type="info" dense color="accent">
                        {{ search ? 'Sin resultados' : 'Sin Actas de Reunión aún' }}
                    </v-alert>
                </v-col>
            </v-row>
        </template>

        <!-- COMPONENTS -->
        <create-meeting-dialog @success="loadMeetings" ref="creator" />

        <kimsa-confirm-delete title="Estas eliminando un Acta de Reunión de:"
            content="Esta acción eliminará de forma permanente el Acta del sistema." component="MeetingCard"
            :current-data="currentData" persistent @confirm="onConfirmDelete"
            @cancel="currentId = null; currentData = null" :loading="loadingDelete" no-close-on-confirm ref="confirm" />

    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import CreateMeetingDialog from "@/components/meeting/CreateMeetingDialog";
import KimsaConfirmDelete from "@/components/common/KimsaConfirmDelete";

export default {
    name: "Meetings",
    components: { KimsaConfirmDelete, CreateMeetingDialog, KimsaTextField },
    data() {
        return {
            loadingSearch: false,
            loadingDelete: false,
            loading: false,
            search: '',
            meetings: [],
            currentPage: 1,
            maxPerPage: 50,
            currentId: null,
            currentData: null,
            headers: [
                { text: 'Título', value: 'name', class: 'grey white--text' },
                { text: 'Fecha', value: 'date', class: 'grey white--text' },
                { text: 'Ubicación', value: 'location', class: 'grey white--text' },
                { text: 'Participantes', value: 'participants', class: 'grey white--text' },
                { text: '', value: 'actions', sortable: false, class: `grey` },
            ],
        }
    },
    computed: {
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadMeetings()
    },
    methods: {
        showCreateMeeting() {
            const vm = this
            vm.$refs.creator.show()
        },
        showEditMeeting(id) {
            const vm = this
            vm.$refs.creator.loadMeeting(id)
        },
        showDeleteMeeting(data) {
            const vm = this
            vm.currentData = data
            vm.currentId = data.id
            vm.$refs.confirm.show()
        },
        showDetailMeeting(id) {
            const vm = this
            vm.$refs.detail.show(id)
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`meeting-record/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete', res)
                vm.toast('Acta de Reunión eliminado')
            }).catch(er => {
                console.log('error onConfirmDelete', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadMeetings()
        },
        async loadMeetings() {
            const vm = this
            vm.loading = true

            let payload = { search: vm.search, per_page: vm.maxPerPage }

            await Axios.post(`client/${vm.clientId}/meeting-records?page=${vm.currentPage}`, payload).then(res => {
                // console.log('res loadMeetings', res)
                vm.meetings = res.data.result.meeting_records.data.map(meeting => {
                    return {
                        id: meeting.id,
                        client: meeting.client?.name || 'n/d',
                        name: meeting.name,
                        date: meeting.date ?
                            vm.$moment(meeting.date).format('DD/MM/YYYY') :
                            vm.$moment(meeting.created_at).format('DD/MM/YYYY'),
                        location: meeting.location || 'n/d',
                        tasks: vm.loadQuantity(meeting.tasks),
                        participants: vm.meetingParticipants(meeting.participants),
                        loadingPdf: false,
                    }
                })
                vm.totalItems = res.data.result.meeting_records.total
            }).catch(er => {
                console.log('error loadMeetings', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        loadQuantity(array) {
            let quantity = array && array.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        meetingParticipants(participants) {
            let quantity = participants && participants.length || 0
            if (quantity === 0) return 'n/d'
            return quantity > 1 ? `${participants[0].name}+${quantity - 1}` : participants[0].name
        },
        async downloadPdf(meeting) {
            const vm = this
            console.log('meeting', meeting)
            meeting.loadingPdf = true

            let url = `meeting-record/${meeting.id}/pdf`

            await Axios.get(url).then(res => {
                console.log('res pdfItem', res)
                const pdf = res.data.result.pdf
                const linkSource = `data:application/pdf;base64,${pdf}`;
                const downloadLink = document.createElement("a");
                const fileName = `${meeting.name} - ${meeting.client.name}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }).catch(er => {
                console.log('er', er)
                vm.toast(`error downloadPDF: ${er}`, 'error')
            }).finally(() => {
                meeting.loadingPdf = false
            })
        },
        customSort(items, index, isDesc) {
            const vm = this
            let isDate = index[0] === 'date'
            items.sort((a, b) => {
                return !isDesc[0] ?
                    vm.compare(a[index[0]]?.toLowerCase(), b[index[0]]?.toLowerCase(), isDate) :
                    vm.compare(b[index[0]]?.toLowerCase(), a[index[0]]?.toLowerCase(), isDate)
            });
            return items;
        },
        compare(a, b, isDate) {
            const vm = this
            if (isDate) {
                return vm.$moment(a, 'DD-MM-YYYY').valueOf() - vm.$moment(b, 'DD-MM-YYYY').valueOf()
            } else {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
            }
        },
        goToVideo() {
            const vm = this
            vm.$router.push({ name: 'meetings.videochat' })
        },
        async updateItemsPerPage(newItemsPerPage) {
            const vm = this
            vm.maxPerPage = newItemsPerPage
            vm.currentPage = 1
            await this.loadDocuments()
        },
    },
    watch: {
        async currentPage() {
            await this.loadMeetings()
        },
        search(val) {
            if (val.length === 0 || val.length >= 3) this.loadMeetings()
        }
    }
}
</script>

<style scoped>
.name-cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 200px;
    max-height: 1.5em;
}

.cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    max-height: 1.5em;
}

.actions-cell-width {
    white-space: nowrap;
    width: 100px;
}
</style>
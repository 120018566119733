<template>
    <v-card v-if="causal" @click="goToCausal" class="pa-2" outlined>
        <v-row align="center">
            <v-col :cols="withActions ? 8 : 12">
                <div v-if="!withActions" class="causal-client">
                    {{ causalClient }}
                </div>
                <div class="causal-title text-truncate">
                    {{ causalName }}
                </div>
                <v-row no-gutters align="center">
                    <v-col cols="6" class="causal-info">
                        <v-icon small>mdi-calendar</v-icon>
                        <b>Fecha Inicio: {{ causalDate }}</b>
                    </v-col>
                    <v-col cols="6" class="causal-info">
                        <v-icon small>mdi-account-outline</v-icon>
                        <b>Comentarios: {{ causalUpdates }}</b>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="withActions" cols="4" align="right">
                <v-btn v-if="!isClient" fab outlined x-small @click.stop="editCausal">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="ml-1" fab outlined x-small @click.stop="deleteCausal">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "CausalCard",
    props: {
        causal: {
            type: Object,
            default: null,
        },
        withActions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        causalName(vm = this) {
            return vm.causal && vm.causal.name || 'n/d'
        },
        causalClient(vm = this) {
            return vm.causal && vm.causal.client && vm.causal.client.name || 'n/d'
        },
        causalDate(vm = this) {
            return vm.causal && vm.$moment(vm.causal.date).format('DD/MM/YYYY') || 'n/d'
        },
        causalUpdates(vm = this) {
            let quantity = vm.causal && vm.causal.updates && vm.causal.updates.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
    },
    methods: {
        goToCausal() {
            const vm = this
            vm.$emit('click')
        },
        editCausal() {
            const vm = this
            vm.$emit('edit')
        },
        deleteCausal() {
            const vm = this
            vm.$emit('delete')
        }
    }
}
</script>

<style scoped>

.causal-client {
    color: var(--v-primary-base);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.causal-title {
    font-size: 15px;
    font-weight: bold;
}

.causal-info {
    font-size: 13px;
    color: #707070;
}

</style>
<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile v-if="!task">
            <loading-spinner/>
        </v-card>
        <v-card tile v-else>
            <v-card-title>
                <v-row no-gutters>
                    <v-col cols="10">

                        <div class="title-dialog">
                            <div class="title-subtext">
                                <v-icon color="black" left size="40">mdi-pin-outline</v-icon>
                                {{ task.client.name }}
                            </div>
                            <div>
                                Detalle de la tarea
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="2" align="right">
                        <v-btn icon color="accent" large @click="hide">
                            <v-icon large>mdi-close-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <div class="label">
                            <b>Responsable</b>
                        </div>
                        <v-card outlined class="pa-3">
                            {{ task.user.name }}
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="label">
                            <b>Fecha Comprometida</b>
                        </div>
                        <v-card outlined class="pa-3">
                            {{ $moment(task.delivery_date).format('DD/MM/YYYY') }}
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="label">
                            <b>Status</b>
                        </div>
                        <v-card outlined class="pa-3">
                            <v-avatar size="20" :color="task.status.color"></v-avatar>
                            {{ task.status.name }}
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <div class="label">
                            <b>Tarea</b>
                        </div>
                        <v-card v-html="task.name" outlined class="pa-3"/>
                    </v-col>
                    <v-col cols="12" class="title-label" >
                        Actualizaciones
                    </v-col>
                    <template v-if="task.comments && task.comments.length > 0">
                        <v-col cols="12" v-for="(item, i) in task.comments" :key="i">
                            <v-row class="mt-n5">
                                <v-col cols="8">
                                    <div class="label">
                                        <b>Comentario</b>
                                    </div>
                                    <v-card v-text="item.comment" outlined class="pa-3"/>
                                </v-col>
                                <v-col cols="4">
                                    <div class="label">
                                        <b>Fecha Actualización</b>
                                    </div>
                                    <v-card outlined class="pa-3">
                                        {{ $moment(item.date).format('DD-MM-YYYY') }}
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                    <v-col cols="12" v-else>
                        Sin actualizaciones aún.
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import LoadingSpinner from "@/components/common/LoadingSpinner";

export default {
    name: "DetailTaskDialog",
    components: {LoadingSpinner},
    data() {
        return {
            dialog: false,
            loading: false,
            task: null,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        taskName(vm = this) {
            return vm.task && vm.task.name.replace(/<[^>]*>?/gm, '') || 'n/d'
        },
    },
    async mounted() {
        const vm = this
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show(id) {
            const vm = this
            await vm.loadTask(id)
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.task = null
        },
        async loadTask(id) {
            const vm = this
            await Axios.get(`manager-task/${id}`).then(res => {
                // console.log('res loadTask', res)
                vm.task = res.data.result.managerTask
            }).catch(er => {
                console.log('error loadTask', er)
            })
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 13px;
    color: black;
    line-height: .5;
}

.label {
    font-size: 15px;
    color: #333333;
}

.title-label {
    color: var(--v-primary-base);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

</style>
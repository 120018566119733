<script>
import { Bar } from "vue-chartjs";

export default {
    name: "BarChart",
    extends: Bar,
    props: {
        title: {
            type: String,
            default: null,
        },
        labels: {
            type: Array,
            default: () => [],
        },
        datasets: {
            type: Array,
            default: () => [],
        },
        hideLegend: {
            type: Boolean,
            default: false,
        },
        salesFulfillment: {
            type: String,
            default: null,
        },
        rightLegend: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        data(vm = this) {
            return {
                labels: vm.labels,
                datasets: vm.datasets,
            }
        },
        options(vm = this) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: !vm.hideLegend,
                    align: vm.rightLegend ? 'end' : 'center',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 8
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value, index, values) {
                                return `$${(value).toLocaleString('es-CL')}`
                            },
                            fontSize: 13
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontSize: 15
                        }
                    }]
                },
                title: {
                    display: !!vm.title,
                    text: vm.title
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return `$${(tooltipItem.yLabel).toLocaleString('es-CL')}`
                        }
                    }
                },
                animation: {
                    onProgress: function () {
                        if(vm.salesFulfillment) {
                            let chartInstance = this.chart;
                            let ctx = chartInstance.ctx;
                            chartInstance.data.datasets.forEach(function (dataset, i) {
                                let meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function (bar, index) {
                                    if(index === 0) {
                                        let centerPoint = bar.getCenterPoint();
                                        ctx.font = 'bolder 30px Arial';
                                        ctx.fillStyle = '#FFFFFF'
                                        ctx.textAlign = "center";
                                        ctx.fillText(`${vm.salesFulfillment}%`, centerPoint.x, centerPoint.y);
                                    }
                                })
                            });
                        }
                    }
                }
            }
        }
    },
    mounted() {
        const vm = this
        vm.renderChart(vm.data, vm.options);
    },
    watch: {
        data() {
            const vm = this
            vm.renderChart(vm.data, vm.options);
        },
        options() {
            const vm = this
            vm.renderChart(vm.data, vm.options);
        }
    }
};
</script>
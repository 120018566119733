import { render, staticRenderFns } from "./KimsaRichEditor.vue?vue&type=template&id=5002bdcc&scoped=true&"
import script from "./KimsaRichEditor.vue?vue&type=script&lang=js&"
export * from "./KimsaRichEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5002bdcc",
  null
  
)

export default component.exports
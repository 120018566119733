<template>
    <v-row v-if="budget">
        <!-- RESULTS -->
        <v-col cols="12">
            <div class="card-chart">
                <!-- SUM RESULTS -->
                <v-row class="px-3" align="center">
                    <v-col cols="12" class="title-chart pb-0">
                        Resultados Acumulados (Real)
                    </v-col>
                    <v-col cols="4" v-for="sum in sumResults" :key="sum.name">
                        <v-row dense class="card-result" align="center">
                            <v-col cols="7">
                                <v-icon>{{ sum.icon }}</v-icon>
                                {{ sum.name }}
                            </v-col>
                            <v-col cols="5" class="result-value">
                                {{ sum.value }}
                                <div class="result-color" :style="`background-color: ${sum.color}`"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- BUDGET RESULTS -->
                <v-row class="px-3" align="center">
                    <v-col cols="12" class="title-chart pb-0">
                        Resultados Proyectados (Proyectados)
                    </v-col>
                    <v-col cols="4" v-for="budget in budgetResults" :key="budget.name">
                        <v-row dense class="card-result" align="center">
                            <v-col cols="7">
                                <v-icon>{{ budget.icon }}</v-icon>
                                {{ budget.name }}
                            </v-col>
                            <v-col cols="5" class="result-value">
                                {{ budget.value }}
                                <div class="result-color" :style="`background-color: ${budget.color}`"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="12">
            <v-row>
                <!-- INDICATORS & FIRST CHART BAR -->
                <v-col cols="4">
                    <!-- FIRST CHART BAR -->
                    <div class="card-chart">
                        <div class="title-chart mb-5">
                            Cumplimiento v/s Presupuesto
                            <div class="border-bottom-accent"/>
                        </div>
                        <v-divider class="mb-3"/>
                        <bar-chart
                            :labels="['Cumplimiento', 'Presupuesto']"
                            :datasets="dataSets"
                            :sales-fulfillment="salesFulfillment"
                            hide-legend
                        />
                    </div>
                    <!-- INDICATORS -->
                    <div class="card-chart mt-3">
                        <div class="title-chart mb-5 text-start">
                            <v-icon>mdi-chart-bar-stacked</v-icon>
                            Principales Indicadores
                        </div>
                        <v-row dense class="subtitle-chart" align="center" v-for="indicator in indicators"
                               :key="indicator.name">
                            <v-col cols="8">
                                {{ indicator.name }}
                            </v-col>
                            <v-col cols="2" class="chart-value" align="end">
                                {{ indicator.value }}
                            </v-col>
                            <v-col cols="2">
                                <div class="chart-color" :style="`background-color: ${indicator.color}`"/>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <div class="border-bottom-accent-full"/>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <!-- YEAR CHART -->
                <v-col cols="8">
                    <div class="card-chart">
                        <div class="title-chart mb-5">
                            Venta Real / Venta Proyectada
                            <div class="border-bottom-accent"/>
                        </div>
                        <v-divider/>
                        <bar-chart
                            :labels="labelMonths"
                            :datasets="yearDataSets"
                            right-legend
                        />
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import BarChart from "@/components/common/BarChart";
import Axios from "@/utils/Axios";

export default {
    name: "BudgetCharts",
    components: {BarChart},
    props: {
        budget: {
            type: Object,
            default: null,
        },
        month: {
            type: String,
            default: 'january',
        },
        isDashboardView: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            colors: {
                green: "#41B883",
                yellow: "#b8ae41",
                red: "#b84141",
                blue: "#4159b8",
                grey: "#acadb0"
            },
            leverage: 0,
            liquidity: 0,
            ros: 0,
            dataSets: null,
            budgetSum: null,
            salesFulfillment: null,
            months: [
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december'
            ],
            currentLastMonth: null,
            sumOpex: '0',
            sumEbitda: '0',
            sumRevenue: '0',
            budgetOpex: '0',
            budgetEbitda: '0',
            budgetRevenue: '0',
            labelMonths: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
            ],
            yearDataSets: null,
        }
    },
    computed: {
        indicators(vm = this) {
            return [
                {name: 'Endeudamiento Simple', value: vm.leverage, color: vm.getColorValue(vm.leverage, 'leverage')},
                {name: 'Liquidez / Liquidity', value: vm.liquidity, color: vm.getColorValue(vm.liquidity, 'liquidity')},
                {name: 'R.O.S', value: vm.ros, color: vm.getColorValue(vm.ros, 'ros')},
            ]
        },
        sumResults(vm = this) {
            let opexColor = Number(vm.sumOpex || 0) <= Number(vm.budgetOpex || 0) ? vm.colors.green : vm.colors.red
            let ebitdaColor = Number(vm.sumEbitda || 0) >= Number(vm.budgetEbitda || 0) ? vm.colors.green : vm.colors.red
            let salesColor = Number(vm.sumRevenue || 0) >= Number(vm.budgetRevenue || 0) ? vm.colors.green : vm.colors.red

            return [
                {
                    name: 'Gasto sobre Ventas',
                    value: vm.sumOpex + '%',
                    color: opexColor,
                    icon: 'mdi-human-male-board'
                },
                {name: 'EBITDA', value: vm.sumEbitda + '%', color: ebitdaColor, icon: 'mdi-poll'},
                {name: 'Venta Acumulada', value: '$' + vm.$options.filters.number(vm.sumRevenue), color: salesColor, icon: 'mdi-poll'},
            ]
        },
        budgetResults(vm = this) {
            return [
                {
                    name: 'Gasto sobre Ventas',
                    value: vm.budgetOpex + '%',
                    color: vm.colors.green,
                    icon: 'mdi-human-male-board'
                },
                {name: 'EBITDA', value: vm.budgetEbitda + '%', color: vm.colors.green, icon: 'mdi-poll'},
                {name: 'Venta Acumulada', value: '$' + vm.$options.filters.number(vm.budgetRevenue), color: vm.colors.green, icon: 'mdi-poll'},
            ]
        },
    },
    mounted() {
        const vm = this
        vm.loadData()
    },
    methods: {
        async loadData() {
            const vm = this

            if (!vm.budget) return
            if (!vm.currentLastMonth) return vm.loadLastMonthWithData()
            // console.log('budget', vm.budget)

            vm.loading = true

            let totalCurrentLiabilities = vm.getSumValues(vm.budget?.financial?.current_liabilities || [])
            let totalLongTerm = vm.getSumValues(vm.budget?.financial?.long_term_liabilities || [])
            let totalCurrentAssets = vm.getSumValues(vm.budget?.financial?.current_assets || [])
            let totalFixedAssets = vm.getSumValues(vm.budget?.financial?.fixed_assets || [])
            let totalOtherAssets = vm.getSumValues(vm.budget?.financial?.other_assets || [])
            let revenue = vm.getSumValues(vm.budget?.financial?.revenue || [])
            let costSale = vm.getSumValues(vm.budget?.financial?.cost_sale || [])
            let operatingExpenses = vm.getSumValues(vm.budget?.financial?.operating_expenses || [])
            let otherExpenses = vm.getSumValues(vm.budget?.financial?.other_expenses || [])

            let totalAssets = totalCurrentLiabilities + totalFixedAssets + totalOtherAssets
            let grossProfit = revenue - costSale;
            let ebitda = grossProfit - operatingExpenses
            let utility = ebitda - otherExpenses

            let revenueBudget = vm.getSumValues(vm.budget?.revenue || [])
            let operatingExpensesBudget = vm.getSumValues(vm.budget?.operatingExpenses || [])
            let costSaleBudget = vm.getSumValues(vm.budget?.costSale || [])
            let grossProfitBudget = revenueBudget - costSaleBudget
            let ebitdaBudget = grossProfitBudget - operatingExpensesBudget

            vm.sumOpex = Number(Number(operatingExpenses / revenue) * 100).toFixed(2).replace('.00', '')
            vm.budgetOpex = Number(Number(operatingExpensesBudget / revenueBudget) * 100).toFixed(2).replace('.00', '')
            vm.sumEbitda = Number(Number(ebitda / revenue) * 100).toFixed(2).replace('.00', '')
            vm.budgetEbitda = Number(Number(ebitdaBudget / revenueBudget) * 100).toFixed(2).replace('.00', '')
            vm.sumRevenue = revenue
            vm.budgetRevenue = revenueBudget

            vm.leverage = Number((totalAssets / (totalCurrentLiabilities + totalLongTerm)) || 0).toFixed(1)
            vm.liquidity = Number((totalCurrentAssets / totalCurrentLiabilities) || 0).toFixed(1)
            vm.ros = Number(((utility / revenue) * 100) || 0).toFixed(2)

            let monthValue = revenue
            vm.salesFulfillment = Number((monthValue / revenueBudget) * 100).toFixed(1)
            vm.dataSets = [
                {
                    data: [{x: 'Cumplimiento', y: monthValue}, {x: 'Presupuesto', y: revenueBudget}],
                    backgroundColor: [vm.getColorValue(vm.salesFulfillment, 'salesFulfillmentValue'), vm.colors.blue]
                },
            ]

            vm.loadYearDataSet()

            vm.loading = false
        },
        loadLastMonthWithData() {
            const vm = this

            let currentMonth = vm.month
            let currentIntent = 1

            for (let i = 0; i < 4; i++) {
                let revenue = vm.getCurrentMonthValue(vm.budget?.financial?.revenue, currentMonth)
                let costSale = vm.getCurrentMonthValue(vm.budget?.financial?.cost_sale, currentMonth)
                let operatingExpenses = vm.getCurrentMonthValue(vm.budget?.financial?.operating_expenses || [], currentMonth)
                let otherExpenses = vm.getCurrentMonthValue(vm.budget?.financial?.other_expenses || [], currentMonth)

                let grossProfit = revenue - costSale;
                let ebitda = grossProfit - operatingExpenses
                let utility = ebitda - otherExpenses

                if (utility !== 0) {
                    vm.currentLastMonth = currentMonth
                    break
                }

                currentIntent++
                currentMonth = vm.getLastMonth(currentMonth)
            }
        },
        getLastMonth(month) {
            const vm = this
            let findIndex = vm.months.findIndex(m => m === month) - 1
            if (findIndex < 0) findIndex = 11
            return vm.months[findIndex]
        },
        getSumValues(array) {
            const vm = this
            let sum = 0
            array.forEach(cl => {
                vm.months.forEach(function loop(month) {
                    if (loop.stop) return;
                    sum += cl[month] || 0
                    if (month === vm.currentLastMonth) loop.stop = true;
                })
            })
            return sum
        },
        getCurrentMonthValue(array, month) {
            const vm = this

            let sum = 0
            array.forEach(cl => {
                sum += cl[month] || 0
            })

            return sum
        },
        getColorValue(value, type) {
            const vm = this
            value = Number(value)
            if (type === 'leverage' || type === 'liquidity') {
                if (value >= 1.5) return vm.colors.green
                if (value >= 1 && value < 1.5) return vm.colors.yellow
                return vm.colors.red
            } else if (type === 'ros') {
                if (value >= 5) return vm.colors.green
                if (value >= 1 && value < 5) return vm.colors.yellow
                return vm.colors.red
            } else {
                if (value >= 85) return vm.colors.green
                if (value >= 60 && value < 85) return vm.colors.yellow
                return vm.colors.red
            }
        },
        loadYearDataSet() {
            const vm = this

            let budgetData = []
            let budgetColors = []
            let realData = []
            let realColors = []

            vm.months.forEach(month => {
                budgetData.push(vm.getSumValuesOfMonth(vm.budget?.revenue || [], month))
                budgetColors.push(vm.colors.grey)
                realData.push(vm.getSumValuesOfMonth(vm.budget?.financial?.revenue || [], month))
                realColors.push(vm.colors.green)
            })

            vm.yearDataSets = [
                {
                    data: realData,
                    backgroundColor: realColors,
                    label: 'Real'
                },
                {
                    data: budgetData,
                    backgroundColor: budgetColors,
                    label: 'Presupuesto'
                },
            ]
        },
        getSumValuesOfMonth(array, month) {
            const vm = this
            let sum = 0
            array.forEach(cl => {
                sum += cl[month] || 0
            })
            return sum
        },
    },
    watch: {
        budget() {
            this.loadData()
        },
        currentLastMonth() {
            this.loadData()
        },
    }
}
</script>

<style scoped>

.title-chart {
    text-align: center;
    color: #5f5f64;
    font-weight: bold;
}

.subtitle-chart {
    color: #5f5f64;
    font-weight: bold;
    font-size: 14px;
}

.chart-value {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.chart-color {
    height: 6px;
    width: 100%;
}

.border-bottom-accent {
    border-bottom: 2px solid #a00a0d;
    margin: auto;
    width: 60%;
}

.border-bottom-accent-full {
    border-bottom: 2px solid #a00a0d;
}

.card-chart {
    background-color: #FFFFFF;
    padding: 16px;
    border-radius: 4px;
}

.card-result {
    background-color: #f3f4f5;
    padding: 8px;
    border-radius: 4px;
    color: #5f5f5f;
    font-size: 14px;
    font-weight: bold;
}

.result-value {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    text-align: center;
}

.result-color {
    height: 6px;
    width: 40%;
    margin: auto;
}

</style>
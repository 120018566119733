<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-pin-outline</v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Crear' }} Tarea
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode || !!clientId"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Responsable"
                            :items="usersOptions"
                            placeholder="Selecciona Responsable"
                            :readonly="!client"
                            bolder-label
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            force-value
                            :value="user"
                            @change="user = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-date-picker
                            label="Fecha Comprometida"
                            placeholder="-- / -- / --"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="date"
                            @change="date = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Status"
                            :items="statusOptions"
                            bolder-label
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            force-value
                            with-avatar-color
                            :value="status"
                            @change="status = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Prioridad"
                            :items="priorityOptions"
                            bolder-label
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            force-value
                            :value="priority"
                            @change="priority = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <div class="label">
                            <b>Tarea</b>
                        </div>
                        <kimsa-rich-editor
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-col cols="12">
                            <text-date-field-add
                                title="Actualizaciones"
                                label="Comentario"
                                second-label="Fecha Actualización"
                                placeholder="Agregar comentario"
                                :template="commentsTemplate"
                                force-value
                                textarea
                                auto-date
                                :value="comments"
                                @change="comments = $event"
                            />
                        </v-col>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editTask() : saveTask()" tile color="primary" depressed
                               :disabled="!valid" :loading="loading">
                            {{ editMode ? 'Editar Tarea' : 'Guardar Tarea' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import KimsaRichEditor from "@/components/common/inputs/KimsaRichEditor";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import TextDateFieldAdd from "@/components/TextDateFieldAdd";

export default {
    name: "CreateTaskDialog",
    components: {
        TextDateFieldAdd,
        KimsaDatePicker,
        KimsaRichEditor,
        KimsaSelect,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            task: null,
            clients: [],
            usersOptions: [],
            statusOptions: [],
            priorityOptions: [],
            client: null,
            user: null,
            date: '',
            status: 1,
            priority: 1,
            name: '',
            comments: [],
            commentsTemplate: {comment: '', date: this.$moment().format('YYYY-MM-DD')},
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.name && !!vm.client && !!vm.user
        },
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadStatus()
        await vm.loadPriorities()
        await vm.loadUsers()
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            await vm.loadClients()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.client = ''
            vm.user = null
            vm.date = ''
            vm.status = 1
            vm.priority = 1
            vm.name = ''
            vm.comments = []
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
                if (vm.clientId) vm.client = Number(vm.clientId)
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadStatus() {
            const vm = this
            await Axios.get(`status`).then(res => {
                // console.log('res loadStatus', res)
                vm.statusOptions = res.data.result.status
            }).catch(er => {
                console.log('error loadStatus', er)
            })
        },
        async loadPriorities() {
            const vm = this
            await Axios.get(`priorities`).then(res => {
                // console.log('res loadPriorities', res)
                vm.priorityOptions = res.data.result.priorities
            }).catch(er => {
                console.log('error loadPriorities', er)
            })
        },
        async loadUsers() {
            const vm = this
            if(!vm.client) return
            await Axios.get(`client/${vm.client}/users`).then(res => {
                // console.log('res loadUsers', res)
                vm.usersOptions = res.data.result.users
            }).catch(er => {
                console.log('error loadUsers', er)
            })
        },
        async loadTask(id) {
            const vm = this
            await Axios.get(`manager-task/${id}`).then(res => {
                // console.log('res loadTask', res)
                vm.task = res.data.result.managerTask
                vm.editMode = true
                vm.client = vm.task.client_id
                vm.user = vm.task.user?.id || null
                vm.date = vm.$moment(vm.task.delivery_date).format('YYYY-MM-DD')
                vm.status = vm.task.status_id
                vm.priority = vm.task.priority_id
                vm.name = vm.task.name
                vm.comments = vm.task.comments || []
                vm.show()
            }).catch(er => {
                console.log('error loadTask', er)
            })
        },
        async saveTask() {
            const vm = this
            vm.loading = true
            console.log('saveTask', vm)

            let payload = {
                client_id: vm.client,
                user_id: vm.user,
                delivery_date: vm.date,
                status_id: vm.status,
                priority_id: vm.priority,
                name: vm.name,
            }

            let comments = vm.comments.filter(res => !!res.comment && !!res.date)
            if (comments.length > 0) payload.comments = comments

            console.log('payload', payload)

            await Axios.post('manager-task/new', payload).then(res => {
                // console.log('res saveTask',res)
                vm.toast('Tarea creada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveTask', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editTask() {
            const vm = this
            vm.loading = true

            console.log('editTask', vm)

            let payload = {
                client_id: vm.client,
                user_id: vm.user,
                delivery_date: vm.date,
                status_id: vm.status,
                priority_id: vm.priority,
                name: vm.name,
            }

            let comments = vm.comments.filter(res => !!res.comment && !!res.date)
            if (comments.length > 0) payload.comments = comments

            console.log('payload', payload)

            await Axios.patch(`manager-task/${vm.task.id}/edit`, payload).then(res => {
                // console.log('res editTask',res)
                vm.toast('Tarea actualizada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editTask', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
        status(val) {
            const vm = this
            if (vm.dialog) {
                let statusName = vm.statusOptions.find(status => status.id === val).name
                if (vm.comments.length === 1 && !vm.comments[0].comment) {
                    vm.comments[0].comment = statusName
                    vm.comments[0].date = vm.$moment().format('YYYY-MM-DD')
                } else {
                    vm.comments.push({
                        comment: statusName,
                        date: vm.$moment().format('YYYY-MM-DD')
                    })
                }
            }
        },
        client(val) {
            if(val) this.loadUsers()
        }
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>
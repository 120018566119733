<template>
    <v-dialog v-model="dialog" fullscreen scrollable transition="dialog-bottom-transition" persistent
        no-click-animation>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-file-document-multiple-outline</v-icon>
                <div class="title-dialog">
                    Cargar nuevos Documento
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer />
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <kimsa-select label="Carpeta" placeholder="Seleccionar carpeta" bolder-label :items="folders"
                            outlined solo flat dense item-text="name" item-value="id" force-value :value="folder"
                            @change="folder = $event" />
                    </v-col>
                    <v-col cols="12">
                        <file-multiple-upload label="Documentos" bolder-label @change="onChangeFiles" ref="files" />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="saveDocuments" tile color="primary" depressed :disabled="!valid"
                            :loading="loading">
                            <v-icon>mdi-upload-outline</v-icon>
                            Confirmar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import Axios from "@/utils/Axios";
import KimsaSelect from '../common/inputs/KimsaSelect.vue';
import FileMultipleUpload from '../common/inputs/FileMultipleUpload.vue';

export default {
    name: "UploadMultipleDocumentDialog",
    components: {
        KimsaSelect,
        FileMultipleUpload,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            files: [],
            name: '',
            folder: null,
            folders: [],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.folder || vm.files.length === 0
        },
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadFolders()
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)

        },
        reset() {
            const vm = this
            vm.folder = null
            vm.files = []
            if (vm.$refs.files) vm.$refs.files.reset()
        },
        async loadFolders() {
            const vm = this
            await Axios.post(`folders`, { client_id: vm.clientId }).then(res => {
                // console.log('res loadFolders', res)
                vm.folders = res.data.result.folders
            }).catch(er => {
                console.log('error loadFolders', er)
            })
        },
        onChangeFiles(files) {
            const vm = this
            vm.files = files
        },
        async saveDocuments() {
            const vm = this
            vm.loading = true
            // console.log('saveDocuments', vm)

            let payload = new FormData()
            payload.append('client_id', vm.clientId)
            payload.append('folder_id', vm.folder)
            vm.files.forEach((file) => {
                payload.append('files[]', file);
            });

            let headers = { headers: { 'Content-Type': 'multipart/form-data' } }
            await Axios.post('document/multiple', payload, headers).then(res => {
                // console.log('res saveDocuments',res)
                vm.toast('Documentos cargados')
                vm.$emit('uploaded')
            }).catch(error => {
                console.log('error saveDocuments', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>
.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}
</style>
<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-account-tie-outline</v-icon>
                <div class="title-dialog">
                    {{ title }} Causa
                    <div v-if="!onlyShow" class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode || !!clientId || onlyShow"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Nombre de Causa"
                            placeholder="Ingresa Causa"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :readonly="onlyShow"
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Rol"
                            placeholder="Ingresa Rol"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :readonly="onlyShow"
                            :value="rol"
                            @change="rol = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-date-picker
                            label="Fecha de Inicio"
                            placeholder="-- / -- / --"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :disabled="onlyShow"
                            :value="date"
                            @change="date = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-col cols="12">
                            <text-date-field-add
                                title="Comentarios"
                                label="Comentario"
                                second-label="Fecha"
                                placeholder="Agregar comentario"
                                :readonly="onlyShow"
                                :template="updatesTemplate"
                                textarea
                                force-value
                                :value="updates"
                                @change="updates = $event"
                            />
                        </v-col>
                    </v-col>
                    <v-col cols="12" align="center" v-if="!onlyShow">
                        <v-btn @click="editMode ? editCausal() : saveCausal()" tile color="primary" depressed
                               :disabled="!valid" :loading="loading">
                            {{ editMode ? 'Editar Causa' : 'Guardar Causa' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import TextDateFieldAdd from "@/components/TextDateFieldAdd";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";

export default {
    name: "CreateCausalDialog",
    components: {
        KimsaTextField,
        TextDateFieldAdd,
        KimsaDatePicker,
        KimsaSelect,
    },
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            causal: null,
            clients: [],
            client: null,
            name: '',
            date: '',
            rol: '',
            updates: [],
            updatesTemplate: {comment: '', date: ''},
            onlyShow: false,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.name && !!vm.client && !!vm.date
        },
        clientId() {
            return this.$route.params.id || null
        },
        title(vm = this) {
            if(vm.onlyShow) return 'Detalle de'
            if(vm.editMode) return 'Editar'
            return 'Crear'
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            await vm.loadClients()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.client = null
            vm.name = ''
            vm.date = ''
            vm.rol = ''
            vm.updates = []
            vm.onlyShow = false
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
                if (vm.clientId) vm.client = Number(vm.clientId)
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadCausal(id, onlyShow = false) {
            const vm = this
            vm.onlyShow = onlyShow
            await Axios.get(`causal/${id}`).then(res => {
                // console.log('res loadCausal', res)
                vm.causal = res.data.result.causal
                vm.editMode = true
                vm.client = vm.causal.client_id
                vm.name = vm.causal.name
                vm.date = vm.$moment(vm.causal.date).format('YYYY-MM-DD')
                vm.rol = vm.causal.rol
                vm.updates = vm.causal.updates || []
                vm.show()
            }).catch(er => {
                console.log('error loadCausal', er)
            })
        },
        async saveCausal() {
            const vm = this
            vm.loading = true
            console.log('saveCausal', vm)

            let payload = {
                client_id: vm.client,
                name: vm.name,
                date: vm.date,
                rol: vm.rol,
            }

            let updates = vm.updates.filter(res => !!res.comment && !!res.date)
            if (updates.length > 0) payload.updates = updates

            console.log('payload', payload)

            await Axios.post('causal/new', payload).then(res => {
                // console.log('res saveCausal',res)
                vm.toast('Causa creada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveCausal', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editCausal() {
            const vm = this
            vm.loading = true

            console.log('editCausal', vm)

            let payload = {
                client_id: vm.client,
                name: vm.name,
                date: vm.date,
                rol: vm.rol,
            }

            let updates = vm.updates.filter(res => !!res.comment && !!res.date)
            if (updates.length > 0) payload.updates = updates

            console.log('payload', payload)

            await Axios.patch(`causal/${vm.causal.id}/edit`, payload).then(res => {
                // console.log('res editCausal',res)
                vm.toast('Causa actualizada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editCausal', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>
<template>
    <div>
        <span v-if="label" :class="['label', { 'bolder-label': bolderLabel }]">{{ label }}</span>
        <div :class="['dropzone-container', { 'dragging': isDragging }]" @dragover="dragover" @dragleave="dragleave"
            @drop="drop">
            <input type="file" multiple class="hidden-input" @change="onChange" ref="file" />

            <v-row>
                <v-col cols="12" v-if="isDragging">
                    Suelta aquí tus documentos
                </v-col>
                <v-col cols="12" v-else>
                    Arrastra tus archivos aqui o <u class="select-file" @click="selectFile">has click aquí</u> para
                    subir documentos
                </v-col>
                <v-col cols="12" class="preview-container" v-if="files.length > 0">
                    <div v-for="file in files" :key="file.name" class="preview-card">
                        <img v-if="isImageFile(file)" class="preview-img" :src="generateURL(file)" />
                        <v-icon v-else class="preview-img" color="#FFFFFF" size="85">mdi-file-document-outline</v-icon>
                        <div class="text-truncate">
                            {{ getFileName(file.name) }}
                        </div>
                        <v-btn small icon color="error" class="delete-btn" @click="remove(files.indexOf(file))">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "KimsaFile",
    props: {
        label: {
            type: String,
            default: '',
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isDragging: false,
            files: [],
        }
    },
    methods: {
        onChange() {
            const self = this;
            let incomingFiles = Array.from(this.$refs.file.files);
            const fileExist = self.files.some((r) =>
                incomingFiles.some(
                    (file) => file.name === r.name && file.size === r.size
                )
            );
            fileExist ? self.toast('Intentaste subir un archivo que ya existia', 'error') : self.files.push(...incomingFiles);
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
        selectFile() {
            this.$refs.file.click()
        },
        isImageFile(file) {
            return file?.type.startsWith('image/')
        },
        getFileName(fileName) {
            return fileName.substring(0, fileName.lastIndexOf('.')) || fileName
        },
        reset() {
            this.files = []
            this.isDragging = false
        }
    },
    watch: {
        files(val) {
            // console.log('file', val)
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>
.label {
    font-size: 15px;
    color: #333333;
}

.bolder-label {
    font-weight: bold;
}

.dropzone-container {
    text-align: center;
    padding: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 2px dashed;
    border-color: #9e9e9e;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.dragging {
    border-color: var(--v-primary-base);
    border-width: 4px;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.select-file {
    cursor: pointer;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.preview-card {
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    width: 120px;
    position: relative;
}

.preview-img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    border: 1px solid #9e9e9e;
    background-color: #9e9e9e;
    display: block;
    margin: 0 auto;
}

.delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.4);
    border-left: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
    backdrop-filter: blur(5px);
    border-radius: 0 8px 0 8px;
}
</style>
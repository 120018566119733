<template>
    <v-container>

        <v-row class="mt-5" align="center">
            <v-col cols="3" align="right">
                <v-avatar size="150" tile class="elevation-3">
                    <v-img v-if="clientImg" :src="`${baseUrl}${clientImg}`" class="" />
                    <v-icon v-else class="py-6" color="gold" size="100">mdi-warehouse</v-icon>
                </v-avatar>
            </v-col>
            <v-col cols="9">
                <v-row>
                    <v-col :cols="isClient ? 12 : 8">
                        <div class="client-title">
                            Detalle Cliente
                        </div>
                        <div class="client-name">
                            {{ clientName }}
                        </div>
                    </v-col>
                    <v-col cols="4" align="right" v-if="!isClient">
                        <v-btn text color="grey" class="normal-btn" @click="showEditClient">
                            Editar cliente
                            <v-icon right>mdi-pencil-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-tabs v-model="currentTab" background-color="transparent">
                            <v-tab v-for="tab in tabsOptions" :key="tab.value" class="client-tabs"
                                :href="`#${tab.value}`">
                                <v-icon>{{ tab.icon }}</v-icon>
                                {{ tab.name }} {{ tab.quantity }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-tabs-items v-model="currentTab" class="mt-5" style="background-color: transparent">
            <v-tab-item v-for="tab in tabsOptions" :key="tab.value" :value="tab.value">
                <component :is="tab.value"></component>
            </v-tab-item>
        </v-tabs-items>

        <!-- COMPONENTS -->
        <create-client-dialog @deleted="onDeleteClient" @success="loadClient" ref="creator" />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import Tasks from "@/components/client/Tasks";
import Budgets from "@/components/client/Budgets";
import Meetings from "@/components/client/Meetings";
import Documents from "@/components/client/Documents";
import CreateClientDialog from "@/components/client/CreateClientDialog";
import Causals from "@/components/client/Causals";

export default {
    name: "ClientDetails",
    components: {
        CreateClientDialog,
        Tasks,
        Budgets,
        Meetings,
        Documents,
        Causals,
    },
    data() {
        return {
            loading: false,
            client: null,
            currentTab: null,
            tabsOptions: [
                { name: 'Presup. y EERR', value: 'Budgets', icon: 'mdi-chart-box-outline' },
                { name: 'Tareas', value: 'Tasks', icon: 'mdi-pin-outline' },
                { name: 'Actas', value: 'Meetings', icon: 'mdi-clipboard-text-outline' },
                { name: 'Documentos', value: 'Documents', icon: 'mdi-file-document-outline' },
                { name: 'Informe de Causas', value: 'Causals', icon: 'mdi-account-tie-outline' },
            ],
        }
    },
    computed: {
        clientId() {
            return this.$route.params.id || null
        },
        clientName(vm = this) {
            return vm.client && vm.client.name || 'n/d'
        },
        baseUrl() {
            return process.env.VUE_APP_BASE_URL || null
        },
        clientImg(vm = this) {
            return vm.client?.filepath || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClient()
        if (vm.$route.query.tab) vm.currentTab = vm.$route.query.tab
    },
    methods: {
        async loadClient() {
            const vm = this
            vm.loading = true

            await Axios.get(`client/${vm.clientId}`).then(res => {
                // console.log('res loadClient',res.data.result)
                vm.client = res.data.result.client
                vm.tabsOptions[0].quantity = vm.loadQuantity('budgets')
                vm.tabsOptions[1].quantity = vm.loadQuantity('manager_tasks')
                vm.tabsOptions[2].quantity = vm.loadQuantity('meeting_records')
                vm.tabsOptions[3].quantity = vm.loadQuantity('documents')
                vm.tabsOptions[4].quantity = vm.loadQuantity('causals')
            }).catch(er => {
                console.log('error loadClient', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        loadQuantity(attribute) {
            const vm = this
            let quantity = vm.client[attribute] && vm.client[attribute].length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        showEditClient() {
            const vm = this
            vm.$refs.creator.loadClient(vm.clientId)
        },
        onDeleteClient() {
            const vm = this
            vm.$store.state.dialogActive = false
            vm.$router.push({ name: 'clients' })
        }
    },
}
</script>

<style scoped>
.client-title {
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.client-name {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--v-primary-base);
}

.client-tabs {
    color: black !important;
    text-transform: none !important;
}
</style>
<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-file-document-outline</v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Cargar nuevo' }} Documento
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode || !!clientId"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <span class="label"><b>Avisos</b></span>
                        <v-checkbox
                            class="mt-1"
                            v-model="notices"
                            :label="`${notices ? 'Con' : 'Sin'} Avisos`"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Nombre del documento"
                            placeholder="Ingresa Nombre"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-select
                            label="Carpeta"
                            placeholder="Seleccionar carpeta"
                            bolder-label
                            :items="folders"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            force-value
                            :value="folder"
                            @change="folder = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <kimsa-file
                            label="Documento"
                            bolder-label
                            outlined
                            dense
                            @change="file = $event"
                            :placeholder="filename"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editDocument() : saveDocument()" tile color="primary" depressed :disabled="!valid" :loading="loading">
                            <v-icon>mdi-upload-outline</v-icon>
                            Confirmar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import KimsaFile from "@/components/common/inputs/KimsaFile";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";

export default {
    name: "UploadDocumentDialog",
    components: {
        KimsaSelect,
        KimsaFile,
        KimsaTextField
    },
    data() {
        return {
            dialog: false,
            loading: false,
            file: null,
            name: '',
            client: null,
            notices: false,
            folder: null,
            clients: [],
            folders: [],
            filename: 'Seleccionar archivo',
            editMode: false,
            document: null,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            if(vm.editMode) return !!vm.name && !!vm.client && !!vm.folder
            return !!vm.file && !!vm.name && !!vm.client && !!vm.folder
        },
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadFolders()
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            await vm.loadClients()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.file = null
            vm.name = ''
            vm.client = null
            vm.notices = false
            vm.folder = null
            vm.filename = 'Seleccionar archivo'
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
                if(vm.clientId) vm.client = Number(vm.clientId)
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadFolders() {
            const vm = this
            await Axios.post(`folders`, {client_id: vm.clientId}).then(res => {
                // console.log('res loadFolders', res)
                vm.folders = res.data.result.folders
            }).catch(er => {
                console.log('error loadFolders', er)
            })
        },
        async loadDocument(id) {
            const vm = this
            await Axios.get(`document/${id}`).then(res => {
                // console.log('res loadDocument', res)
                vm.document = res.data.result.document
                vm.editMode = true
                vm.name = vm.document.name
                vm.client = vm.document.client_id
                vm.notices = vm.document.notices || false
                vm.folder = vm.document.folder_id
                vm.filename = vm.document.filename
                vm.show()
            }).catch(er => {
                console.log('error loadDocument', er)
            })
        },
        async saveDocument() {
            const vm = this
            vm.loading = true
            // console.log('saveDocument', vm)

            let payload = new FormData()
            payload.append('client_id',vm.client)
            payload.append('folder_id',vm.folder)
            payload.append('name',vm.name)
            payload.append('notices',vm.notices)
            payload.append('file',vm.file)

            let headers = {headers: {'Content-Type': 'multipart/form-data'}}
            await Axios.post('document/new', payload, headers).then(res => {
                // console.log('res saveDocument',res)
                vm.toast('Documento cargado')
                vm.$emit('uploaded')
            }).catch(error => {
                console.log('error saveDocument', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editDocument() {
            const vm = this
            vm.loading = true

            // console.log('editDocument', vm)

            let payload = new FormData()
            payload.append('_method', 'PATCH');
            payload.append('folder_id', vm.folder)
            payload.append('name', vm.name)
            payload.append('notices',vm.notices)
            if (vm.file) payload.append('file', vm.file)

            let headers = {headers: {'Content-Type': 'multipart/form-data'}}
            await Axios.post(`document/${vm.document.id}/edit`, payload, headers).then(res => {
                // console.log('res editDocument', res)
                vm.toast('Documento editado')
                vm.$emit('uploaded')
            }).catch(error => {
                console.log('error editDocument', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>
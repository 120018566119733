<template>
    <div>
        <span v-if="label" :class="['label', {'bolder-label': bolderLabel}]">{{ label }}</span>
        <v-file-input
            :color="color"
            :outlined="outlined"
            :dense="dense"
            :hide-details="!showDetails"
            :disabled="disabled"
            :loading="loading"
            :placeholder="placeholder"
            @change="file = $event"
        ></v-file-input>
    </div>
</template>

<script>
export default {
    name: "KimsaFile",
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: '',
        },
        elevation: {
            type: Boolean,
            default: false,
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            file: '',
        }
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value) {
                vm.file = vm.value
            }
        },
        reset() {
            const vm = this
            vm.file = ''
        },
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    watch: {
        value() {
            this.setDefaults()
        },
        file(val) {
            // console.log('file', val)
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.bolder-label {
    font-weight: bold;
}

</style>
<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-clipboard-text-outline</v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Crear' }} Acta de Reunión
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode || !!clientId"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-select
                            label="Participantes"
                            :items="participantsOptions"
                            placeholder="Selecciona participantes"
                            bolder-label
                            outlined solo
                            flat dense
                            multiple
                            item-text="name"
                            item-value="id"
                            :readonly="!client"
                            create-participant-button
                            :client-id="client"
                            force-value
                            :value="participants"
                            @change="participants = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <kimsa-text-field
                            label="Título de Reunión"
                            placeholder="Ingresa Título"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-text-field
                            label="Ubicación"
                            placeholder="¿Dónde se realizo la reunión?"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="location"
                            @change="location = $event"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <kimsa-date-picker
                            label="Fecha"
                            placeholder="-- / -- / --"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="date"
                            @change="date = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <text-field-add
                            label="Resoluciones"
                            placeholder="Ingresar Resoluciones"
                            :template="resolutionsTemplate"
                            force-value
                            :value="resolutions"
                            @change="resolutions = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <div class="label">
                            <b>Redacción</b>
                        </div>
                        <kimsa-rich-editor
                            force-value
                            :value="description"
                            @change="description = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <div class="label">
                            <b>Tareas</b>
                        </div>
                        <add-task-field
                            force-value
                            :readonly="editMode"
                            :users-options="usersOptions"
                            :status-options="statusOptions"
                            :priority-options="priorityOptions"
                            :value="tasks"
                            @change="tasks = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editMeeting() : saveMeeting()" tile color="primary" depressed :disabled="!valid" :loading="loading">
                            Guardar Acta de Reunión
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import KimsaRichEditor from "@/components/common/inputs/KimsaRichEditor";
import TextFieldAdd from "@/components/TextFieldAdd";
import AddTaskField from "@/components/task/AddTaskField";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";

export default {
    name: "CreateMeetingDialog",
    components: {
        KimsaDatePicker,
        AddTaskField,
        TextFieldAdd,
        KimsaRichEditor,
        KimsaSelect,
        KimsaTextField
    },
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            meeting: null,
            clients: [],
            participantsOptions: [],
            usersOptions: [],
            statusOptions: [],
            priorityOptions: [],
            name: '',
            client: null,
            participants: [],
            location: '',
            date: '',
            description: '',
            resolutions: [],
            resolutionsTemplate: {comment: ''},
            tasks: [],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.name && !!vm.client && !!vm.date
        },
        clientId() {
            return this.$route.params.id || null
        },
    },
    async mounted() {
        const vm = this
        await vm.loadParticipants()
        await vm.loadStatus()
        await vm.loadPriorities()
        await vm.loadUsers()
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            await vm.loadClients()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.name = ''
            vm.client = null
            vm.participants = []
            vm.location = ''
            vm.date = ''
            vm.description = ''
            vm.resolutions = []
            vm.tasks = []
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
                if(vm.clientId) vm.client = Number(vm.clientId)
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadParticipants() {
            const vm = this
            if(!vm.client) return
            await Axios.get(`clients/${vm.client}/participants`).then(res => {
                // console.log('res loadParticipants', res)
                vm.participantsOptions = res.data.result.participants
            }).catch(er => {
                console.log('error loadParticipants', er)
            })
        },
        async loadStatus() {
            const vm = this
            await Axios.get(`status`).then(res => {
                // console.log('res loadStatus', res)
                vm.statusOptions = res.data.result.status
            }).catch(er => {
                console.log('error loadStatus', er)
            })
        },
        async loadPriorities() {
            const vm = this
            await Axios.get(`priorities`).then(res => {
                // console.log('res loadPriorities', res)
                vm.priorityOptions = res.data.result.priorities
            }).catch(er => {
                console.log('error loadPriorities', er)
            })
        },
        async loadUsers() {
            const vm = this
            if(!vm.client) return
            await Axios.get(`client/${vm.client}/users`).then(res => {
                // console.log('res loadUsers', res)
                vm.usersOptions = res.data.result.users
            }).catch(er => {
                console.log('error loadUsers', er)
            })
        },
        async loadMeeting(id) {
            const vm = this
            await Axios.get(`meeting-record/${id}`).then(res => {
                // console.log('res loadMeeting', res)
                vm.meeting = res.data.result.meetingRecord
                vm.editMode = true
                vm.name = vm.meeting.name
                vm.client = vm.meeting.client_id
                vm.participants = vm.meeting.participants.map(participant => participant.id)
                vm.location = vm.meeting.location
                vm.date = vm.meeting.date && vm.$moment(vm.meeting.date).format('YYYY-MM-DD') || ''
                vm.description = vm.meeting.description
                vm.resolutions = vm.meeting.resolutions || []
                vm.tasks = vm.meeting.tasks || []
                vm.show()
            }).catch(er => {
                console.log('error loadMeeting', er)
            })
        },
        async saveMeeting() {
            const vm = this
            vm.loading = true
            console.log('saveMeeting', vm)

            let payload = {
                client_id: vm.client,
                name: vm.name,
                date: vm.date,
            }

            let resolutions = vm.resolutions.filter(res => !!res.comment)
            let tasks = vm.tasks.filter(task => !!task.name && !!task.user && !!task.date)

            if (vm.participants.length > 0) payload.participants = vm.participants
            if (vm.location) payload.location = vm.location
            if (vm.description) payload.description = vm.description
            if (resolutions.length > 0) payload.resolutions = resolutions
            if (tasks.length > 0) payload.tasks = tasks

            console.log('payload', payload)

            await Axios.post('meeting-record/new', payload).then(res => {
                // console.log('res saveMeeting',res)
                vm.toast('Acta de reunión creada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveMeeting', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editMeeting() {
            const vm = this
            vm.loading = true

            // console.log('editMeeting', vm)

            let payload = {
                client_id: vm.client,
                name: vm.name,
                date: vm.date,
            }

            let resolutions = vm.resolutions.filter(res => !!res.comment)
            let tasks = vm.tasks.filter(task => !!task.name && !!task.responsible && !!task.date)

            if (vm.participants.length > 0) payload.participants = vm.participants
            if (vm.location) payload.location = vm.location
            if (vm.description) payload.description = vm.description
            if (resolutions.length > 0) payload.resolutions = resolutions
            if (tasks.length > 0) payload.tasks = tasks

            console.log('payload edit', payload)

            await Axios.patch(`meeting-record/${vm.meeting.id}/edit`, payload).then(res => {
                // console.log('res editMeeting',res)
                vm.toast('Acta de reunión actualizada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editMeeting', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
        client(val) {
            if(val) this.loadParticipants(); this.loadUsers()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>
<template>
    <v-dialog
        v-model="dialog"
        :persistent="persistent"
        max-width="500"
        @click:outside="onClickOutside"
    >
        <v-card class="py-3" v-if="currentData">
            <div class="card-delete py-10">
                <div class="title-card">
                    <v-icon color="primary" size="70">mdi-delete-outline</v-icon>
                    <br>
                    {{ title }}
                    <br>
                    {{ clientName }}
                </div>

                <v-card-text>
                    <meeting-card v-if="component === 'MeetingCard'" :meeting="currentData" no-client-name/>
                    <task-card v-if="component === 'TaskCard'" :task="currentData"/>
                    <document-card v-if="component === 'DocumentCard'" :document="currentData" :with-actions="false"/>
                    <causal-card v-if="component === 'CausalCard'" :causal="currentData"/>

                    <div class="content-text">
                        {{ content }}
                        <br>
                        ¿Estas seguro?
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn depressed color="grey" dark @click="onCancel" :disabled="loading">
                        Cancelar
                    </v-btn>
                    <v-btn :loading="loading" dark depressed color="accent" @click="onConfirm">
                        <v-icon>mdi-delete-outline</v-icon>
                        Eliminar
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>

            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import MeetingCard from "@/components/meeting/MeetingCard";
import TaskCard from "@/components/task/TaskCard";
import DocumentCard from "@/components/document/DocumentCard";
import CausalCard from "@/components/causal/CausalCard";

export default {
    name: "KimsaConfirmDelete",
    components: {DocumentCard, TaskCard, MeetingCard, CausalCard},
    props: {
        title: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noCloseOnConfirm: {
            type: Boolean,
            default: false,
        },
        noDialogActive: {
            type: Boolean,
            default: false,
        },
        currentData: {
            type: Object,
            default: null,
        },
        component: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        clientName() {
            return this.currentData?.client?.name || ''
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            if(!vm.noDialogActive) vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            if(!vm.noDialogActive) vm.setDialogActive(false)
        },
        onConfirm() {
            const vm = this
            vm.$emit('confirm')
            if(!vm.noCloseOnConfirm) vm.hide()
        },
        onCancel() {
            const vm = this
            vm.$emit('cancel')
            vm.hide()
        },
        onClickOutside() {
            const vm = this
            if(!vm.persistent) return vm.hide()
        }
    },
    watch: {
        dialogActive(val) {
            if (!val && !this.noDialogActive) this.hide()
        },
    }
}
</script>

<style scoped>

.card-delete {
    width: calc(100% - 24px);
    border: 1px solid var(--v-primary-base);
    margin: auto;
}

.title-card {
    text-align: center;
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-weight: bold;
    font-size: 20px;
}

.content-text {
    margin-top: 24px;
    color: #777777;
    text-align: center;
    font-size: 13px;
}

</style>
<template>
    <v-row no-gutters align="center">
        <v-col :cols="readonly ? 12 :11" class="title-label">
            {{ title }}
        </v-col>
        <v-col v-if="!readonly" cols="1" align="center">
            <v-btn icon @click="addRow()">
                <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="8" class="label">
            <b>{{ label }}</b>
        </v-col>
        <v-col cols="4" class="label pl-3">
            <b>{{ secondLabel }}</b>
        </v-col>
        <v-col cols="12" v-for="(item, i) in model" :key="i" class="mt-3">
            <v-row>
                <v-col cols="8">
                    <kimsa-textarea-field
                        v-if="textarea"
                        :placeholder="placeholder"
                        outlined solo flat dense
                        force-value :readonly="readonly"
                        :value="model[i].comment"
                        @change="model[i].comment = $event"
                    />
                    <kimsa-text-field
                        v-else
                        :placeholder="placeholder"
                        outlined solo flat dense
                        force-value :readonly="readonly"
                        :value="model[i].comment"
                        @change="model[i].comment = $event"
                    />
                </v-col>
                <v-col cols="4">
                    <kimsa-date-picker
                        placeholder="-- / -- / --"
                        outlined solo flat dense
                        :height="textarea ? '90px': undefined"
                        force-value :disabled="readonly || autoDate"
                        :value="model[i].date"
                        @change="model[i].date = $event"
                        :append-icon="i !== 0 && (!readonly || !autoDate) ? 'mdi-minus-circle-outline' : ''"
                        @click:append="deleteRow(i)"
                    />
                </v-col>
                <v-col cols="12" class="mb-3" v-if="model.length > i+1">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import KimsaTextareaField from "@/components/common/inputs/KimsaTextareaField";
export default {
    name: "TextDateFieldAdd",
    components: {KimsaTextareaField, KimsaDatePicker, KimsaTextField},
    props: {
        title: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        secondLabel: {
            type: String,
            default: null
        },
        template: {
            type: Object,
            default: null
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: null,
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        textarea: {
            type: Boolean,
            default: false
        },
        autoDate: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: [],
        }
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    methods: {
        addRow() {
            const vm = this
            vm.model = JSON.parse(JSON.stringify(vm.model))
            vm.model.push(JSON.parse(JSON.stringify(vm.template)))
        },
        deleteRow(index) {
            const vm = this
            vm.model.splice(index, 1)
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
            if(vm.model.length === 0) vm.addRow()
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.title-label {
    color: var(--v-primary-base);
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

</style>